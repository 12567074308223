import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
interface Props {
  onClick: () => void;
  title: string;
  isTraining? : boolean
}

const BackButton: React.FC<Props> = ({ onClick, title,isTraining }) => {
  return (
    <div
      className={`absolute left-0 flex items-center justify-between h-[50px] w-full px-[14px] ${isTraining ? "bg-heavy-blue" : "bg-red-primary"} rounded-b-[16px]`}
      onClick={onClick}
    >
      <ArrowBackIosNewRoundedIcon
        sx={{
          color: "#FFFFFF",
        }}
      />
      <p className="text-white">{title}</p>
      <div className="w-[24px]"></div>
    </div>
  );
};

export default BackButton;
