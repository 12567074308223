import { Link } from "react-router-dom";
import { RedemtionContext } from "pages/Redemtion";
import dayjs from "dayjs";
import { useContext } from "react";

const Success = () => {
  const { rewardRedeem } = useContext(RedemtionContext);
  return (
    <div className="pt-[45px] text-center">
      <img
        className="mx-auto mb-[80px]"
        src="/images/check.png"
        alt="check-icon"
      />
      <div className="mb-[135px] space-y-[30px]">
        <p className="title">การทำรายการสำเร็จ</p>
        <p className="text-light-green body-b">{rewardRedeem?.code}</p>
        <div>
          <p className="label-m text-gray">รางวัล: {rewardRedeem?.reward.cashback} THB</p>
          <p className="label-m text-gray">
            แลกรางวัลเมื่อ : {dayjs(rewardRedeem?.redeemed_at).format("DD/MM/YYYY HH:mm")} น.
          </p>
        </div>
      </div>
      <Link to="/reward">
        <button
          className="bg-light-green text-white w-full h-[45px] rounded-lg"
          type="button"
        >
          กลับสู่หน้าหลัก
        </button>
      </Link>
    </div>
  );
};

export default Success;
