import { IReward } from "Interfaces/Reward";

interface Props {
  reward: IReward;
  disable: boolean;
}

const RewardCard: React.FC<Props> = ({ reward, disable }) => {
  return (
    <div className="flex items-center h-[77px] px-[15px] bg-secodary-white rounded-lg">
      <img
        className="h-[48px] mr-[15px]"
        src={
          process.env.REACT_APP_FILE +
          (disable ? `${reward.image_disable}` : `${reward.image}`)
        }
        alt="discount-icon"
      />
      <p className="body-b">
        {reward.spend.toLocaleString()} THB เพื่อแลกคูปองเงินสด{" "}
        {reward.cashback.toLocaleString()} THB
      </p>
    </div>
  );
};

export default RewardCard;
