import { getLineProfile, login } from "util/simulator-bay/auth";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loading from "components/Loading";
import Swal from "sweetalert2";
import { UserContext } from "contexts/simulator-bay/UserContext";
import liff from "@line/liff/dist/lib";

const withoutAuth = (WrapperComponent: any) => (props: any) => {
  let navigate = useNavigate();

  const { onSetProfile, onSetToken, profile, token } =
    useContext(UserContext);
  const [isReady, setIsReady] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();

  let isTraining = useMemo(() => {
    let type = searchParams.get("type");
    return type === "training";
  }, [searchParams]);

  useEffect(() => {
    let lineProfile = profile;
    let tokenRes = token;
    let isTraining = searchParams.get("type") === "training";

    const getReady = async () => {
      if (!lineProfile) {
        await getLineProfile()
          .then((lineProfileRes) => {
            lineProfile = lineProfileRes;
            onSetProfile(lineProfileRes);
            console.log("line: ", lineProfile)
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              html: "ไม่สามารถดึงข้อมูลของผู้ใช้งานจากไลน์ได้ กรุณาลองอีกครั้ง",
              confirmButtonText: "ตกลง",
              confirmButtonColor: "#F61E00",
            }).then(() => {
              liff.closeWindow();
            });
          });
      }
      if (lineProfile) {
        if (!tokenRes) {
          await login(lineProfile.userLineID)
            .then((tokenResponse: string) => {
              tokenRes = tokenResponse;
              onSetToken(tokenResponse);
              navigate(`/simulator-bay/member${isTraining ? "type=training" : ""}`);
            })
            .catch((err) => {
              setIsReady(true);
            });
        }
        if (tokenRes) {
          navigate(`/simulator-bay/member${isTraining ? "type=training" : ""}`);
        }
      }
    };

    getReady();
  }, []);

  if (!isReady) {
    return <Loading color={isTraining ? "info" : "error"} />;
  }
  return <WrapperComponent {...props} />;
};

export default withoutAuth;
