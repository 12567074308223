import React, { useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import InputPhone from "components/simulatorBay/InputPhone";
import Pdpa from "components/simulatorBay/Pdpa";
import PersonalInfo from "components/simulatorBay/PersonalInfo";
import Term from "components/simulatorBay/Term";
import VerifyCode from "components/simulatorBay/VerifyCode";
import withoutAuth from "hoc/simulator-bay/withoutAuth";

export const SimulatorBayContext = React.createContext({
  step: 1,
  verify: {
    otp: "",
    ref: "",
  },
  inputPhone: "",
  onChangeStep: (stepProps: number) => {},
  onSetVerify: (otpProps: string, refProps: string) => {},
  random: (characters: string, length: number) => {
    return "" as string;
  },
  onSetPhoneNumber: (inputPhoneProps: string) => {},
});

const SimulatorBayRegister = () => {
  const [step, setStep] = useState<number>(1);
  const [verify, setVerify] = useState<{ otp: string; ref: string }>({
    otp: "",
    ref: "",
  });
  const [inputPhone, setInputPhone] = useState<string>("");

  useEffect(() => {
    console.log(verify);
  }, [verify]);

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  const onSetVerify = (otpProps: string, refProps: string) => {
    setVerify({
      otp: otpProps,
      ref: refProps,
    });
  };

  const onSetPhoneNumber = (inputPhoneProps: string) => {
    setInputPhone(inputPhoneProps);
  };

  const random = (characters: string, length: number) => {
    var result = "";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <Term />;
      case 3:
        return <InputPhone />;
      case 4:
        return <VerifyCode />;
      case 5:
        return <PersonalInfo />;
      default:
        return <Pdpa />;
    }
  }, [step]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register - CCK GIC</title>
      </Helmet>
      <div className="container">
        <SimulatorBayContext.Provider
          value={{
            step,
            verify,
            inputPhone,
            onChangeStep,
            onSetVerify,
            random,
            onSetPhoneNumber,
          }}
        >
          {StepComponent}
        </SimulatorBayContext.Provider>
      </div>
    </>
  );
};

export default withoutAuth(SimulatorBayRegister);
