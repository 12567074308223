import React, { useEffect, useState } from "react";

import { ICoupon } from "Interfaces/Coupon";
import dayjs from "dayjs";

interface Props {
  popup: boolean;
  handlePopup: (isShow: boolean, coupon: ICoupon | null) => void;
  handleSubmit: () => void;
  handleSubmitSpecial: () => void;
  coupon: ICoupon | null;
}

const CouponDetail: React.FC<Props> = ({
  popup,
  handlePopup,
  handleSubmit,
  handleSubmitSpecial,
  coupon,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (popup) {
      setIsOpen(true);
    } else {
      setTimeout(() => {
        setIsOpen(false);
      }, 250);
    }
  }, [popup]);

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-screen h-screen`}
    >
      <div className="relative w-full h-full">
        <div
          className={`w-full h-full bg-black transition-all duration-300 ${
            popup ? "opacity-10" : "opacity-0"
          }`}
          onClick={() => handlePopup(false, null)}
        ></div>
        <div
          className={`absolute bottom-0 w-full bg-secodary-white pt-[15px] pb-[35px] px-[22px] rounded-t-[18px] ${
            popup ? "animate-coupon-in" : "animate-coupon-out"
          }`}
        >
          <p className="heading mb-[10px] text-black">{coupon?.name}</p>
          <div
            className="label-m mb-[10px]"
            dangerouslySetInnerHTML={{ __html: coupon?.description as string }}
          />
          <div className="remark mb-[15px]">
            <p className="text-red">*เงื่อนไขการใช้งานคูปอง</p>
            <ol className="list-decimal px-[22px]">
              <li className="text-black">
                กรุณานำโทรศัพท์เคลื่อนที่ของท่านมากดรับสิทธิ์ ณ จุดแลกสิทธิ์
                และไม่สามารถรับสิทธิ์ได้จากการแสดงภาพที่บันทึกสิทธิ์บนหน้าจอโทรศัพท์
              </li>
              <li className="text-black">
                สงวนสิทธิ์สำหรับสมาชิก Line Official ของ Only Golf เท่านั้น
              </li>
              <li className="text-black">
                กรณีทำรายการแลกพอยท์แล้ว ไม่สามารถยกเลิกได้ทุกกรณี
              </li>
              <li className="text-black">
                ไม่สามารถแลกเปลี่ยนหรือทอนเป็นเงินสดได้
              </li>
              <li className="text-black">
                ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่น
              </li>
            </ol>
          </div>
          <div className="grid grid-cols-2 content-center divide-x divide-light-gray h-[34px] remark mb-[15px]">
            <div>
              <p>คะแนน</p>
              {coupon?.coupon_type.name === "SPECIAL" ? (
                <>คะแนนเท่ายอดสั่งซื้อ</>
              ) : (
                <>{coupon?.point?.toLocaleString()} คะแนน</>
              )}
            </div>
            <div className="pl-[21px]">
              <p>ใช้ได้ถึง</p>
              <p>{dayjs(coupon?.ended_at).format("DD/MM/YYYY")}</p>
            </div>
          </div>
          <button
            className={`body-b ${
              coupon?.is_enabled ? "bg-light-green" : "bg-light-gray"
            } text-white w-full h-[45px] rounded-[8px]`}
            onClick={() => {
              if (
                coupon?.coupon_type &&
                coupon.coupon_type.name === "SPECIAL"
              ) {
                handleSubmitSpecial();
              } else {
                handleSubmit();
              }
              handlePopup(false, null);
            }}
            disabled={coupon?.is_enabled === false}
          >
            {coupon?.is_enabled ? "ตกลง" : "ไม่ตรงตามเงื่อนไข"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CouponDetail;
