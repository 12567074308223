import { getLineProfile, getUser, login } from "util/auth";
import { useContext, useEffect, useState } from "react";

import { IUser } from "Interfaces/User";
import Loading from "components/Loading";
import Swal from "sweetalert2";
import { UserContext } from "contexts/UserContext";
import liff from "@line/liff/dist/lib";
import { useNavigate } from "react-router-dom";

const withAuth = (WrapperComponent: any) => (props: any) => {
  let navigate = useNavigate();

  const { onSetProfile, onSetToken, onSetUser, profile, token, user } =
    useContext(UserContext);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    let lineProfile = profile;
    let tokenRes = token;

    const getReady = async () => {
      if (!lineProfile) {
        await getLineProfile()
          .then((lineProfileRes) => {
            lineProfile = lineProfileRes;
            onSetProfile(lineProfileRes);
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              html: "ไม่สามารถดึงข้อมูลของผู้ใช้งานจากไลน์ได้ กรุณาลองอีกครั้ง",
              confirmButtonText: "ตกลง",
              confirmButtonColor: "#F61E00",
            }).then(() => {
              liff.closeWindow();
            });
          });
      }
      if (lineProfile) {
        if (!tokenRes) {
          await login(lineProfile.userLineID)
            .then((tokenResponse: string) => {
              tokenRes = tokenResponse;
              onSetToken(tokenResponse);
            })
            .catch((err) => {
              navigate("/register");
            });
        }
        if (tokenRes) {
          if (!user) {
            await getUser(tokenRes)
              .then((userRes: IUser) => {
                onSetUser(userRes);
                setIsReady(true);
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  html: "ไม่สามารถดึงข้อมูลของผู้ใช้งานจากระบบได้ กรุณาลองอีกครั้ง",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#F61E00",
                }).then(() => {
                  liff.closeWindow();
                });
              });
          } else {
            setIsReady(true);
          }
        }
      }
    };

    getReady();
  }, []);

  if (!isReady) {
    return <Loading />;
  }
  return <WrapperComponent {...props} />;
};

export default withAuth;
