import { ILineProfile, IUser } from "Interfaces/User";
import React, { ReactNode, createContext, useState } from "react";

interface IUserContext {
  user: IUser | null;
  token: string | null;
  profile: ILineProfile | null;
  onSetUser: (data: IUser) => void;
  onSetToken: (token: string) => void;
  onSetProfile: (data: ILineProfile) => void;
}

const userContextDefault: IUserContext = {
  user: null,
  token: null,
  profile: null,
  onSetUser: (data: IUser) => {},
  onSetToken: (token: string) => {},
  onSetProfile: (data: ILineProfile) => {},
};

export const UserContext = createContext<IUserContext>(userContextDefault);

const UserContextStore = (): IUserContext => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [profile, setProfile] = useState<ILineProfile | null>(null);

  const onSetUser = (data: IUser) => {
    setUser(data);
  };

  const onSetToken = (token: string) => {
    setToken(token);
  };

  const onSetProfile = (data: ILineProfile) => {
    setProfile(data);
  };

  return {
    user,
    token,
    profile,
    onSetUser,
    onSetToken,
    onSetProfile,
  };
};

interface Props {
  children?: ReactNode;
}

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <UserContext.Provider value={UserContextStore()}>
      {children}
    </UserContext.Provider>
  );
};
