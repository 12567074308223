import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BackButton from "components/common/BackButton";
import { FaPhoneAlt } from "react-icons/fa";
import { IProfessional } from "Interfaces/simulator-bay/User";
import Loading from "components/Loading";
import LoadingSection from "components/LoadingSection";
import ProfessionalServices from "services/simulator-bay/Professional";

const Profile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [professional, setProfessional] = useState<IProfessional | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ProfessionalServices.getProfessionalById(parseInt(id as string))
      .then((res) => {
        setProfessional(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading color="error" />;
  }
  if (professional) {
    return (
      <div>
        <BackButton
          onClick={() => navigate("/simulator-bay/professional-profiles")}
          title="Professional Profiles"
          isTraining
        />
        <div className="container pt-[75px] pb-[150px]">
          <img
            src={
              professional?.profile_url
                ? process.env.REACT_APP_FILE + professional.profile_url
                : "/profile.png"
            }
            className="object-cover w-[135px] h-[135px] mx-auto mb-[12px] border-4 border-white rounded-full"
            alt="profile"
          />
          <p className="title text-center mb-[22px]">
            {professional?.first_name} {professional?.last_name}
          </p>
          <div className="px-[26px] py-[17px] mb-[40px] bg-white rounded-[18px] drop-shadow-[0_15px_40px_rgba(43,43,43,0.1)]">
            <p className="mb-[8px] label-b text-heavy-blue">
              Profile/Experience :
            </p>
            <div
              className="mb-[14px] caption-m"
              dangerouslySetInnerHTML={{
                __html: professional?.experience || "",
              }}
            />
            <p className="mb-[8px] label-b text-heavy-blue">Type :</p>
            <p className="mb-[14px] caption-m">{professional.type}</p>
            <p className="mb-[8px] label-b text-heavy-blue">Age :</p>
            <p className="mb-[14px] caption-m">{professional.age || "-"}</p>
            <p className="mb-[8px] label-b text-heavy-blue">Phone number :</p>
            <p className="mb-[14px] caption-m">{professional.tel || "-"}</p>
            <p className="mb-[8px] label-b text-heavy-blue">Price :</p>
            <p className="mb-[8px] caption-m">{professional.price}</p>
            <p className="mb-[8px] label-b text-heavy-blue">
              Teaching Time :
            </p>
            <div
              className="mb-[15px] caption-m"
              dangerouslySetInnerHTML={{
                __html: professional?.available_time || "",
              }}
            />
          </div>
          {/* <p className="mb-[15px] body-b">Available time</p> */}
          {/* <div className="space-y-[20px]">
            {professional && professional.free_slots.length > 0 ? (
              professional?.free_slots.map((day) => {
                return (
                  <div>
                    <p className="label-m text-gray">{day.day.name}</p>
                    <div>
                      {day.times.map((time) => {
                        return (
                          <div className="inline-block py-[5px] px-[20px] mr-[15px] mt-[8px] bg-red-btn rounded-lg">
                            <p className="caption-b text-white">
                              {time.time.time_start.substring(0, 5)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center">No slot time</div>
            )}
          </div> */}
          <div className="fixed left-0 bottom-0 px-[35px] py-[30px] w-full bg-white">
            <a href={`tel:0967078883`}>
              <button className="flex items-center justify-center space-x-[15px] w-full h-[45px] bg-heavy-blue rounded-lg">
                <FaPhoneAlt className=" text-white" />
                <p className="text-white">Contact CCK</p>
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>404 - Page not found</div>;
  }
};

export default Profile;
