import { IRewardRedeem } from "Interfaces/Reward";
import dayjs from "dayjs";

interface Props {
  history: IRewardRedeem;
}

const HistoryCard: React.FC<Props> = ({ history }) => {
  return (
    <div className="flex items-center space-x-[11px] bg-secodary-white py-[15px] px-[11px] rounded-lg">
      <img
        className="w-[48px] rounded-lg"
        src={process.env.REACT_APP_FILE + history.reward.image_disable}
        alt="coupon-icon"
      />
      <div>
        <p className="caption-b text-light-gray">
          {history.reward.spend.toLocaleString()} THB เพื่อคูปองเงินสด
          {history.reward.cashback.toLocaleString()} THB
        </p>
        <p className="remark-b text-light-gray">
          หมายเลขการทำรายการ : {history.code}
        </p>
        <p className="remark-b text-light-gray">
          ใช้งานเมื่อ : {dayjs(history.redeemed_at).format("DD/MM/YYYY HH:mm")}
        </p>
      </div>
    </div>
  );
};

export default HistoryCard;
