import axios from "axios";

const BookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-booking`,
});

const BookingServices = {
  booking: (
    token: string,
    data: {
      bay_number: number;
      booking_date: string;
      start_time: string;
      total_hour: number;
      professional_id?: number
    }
  ) =>
    BookingService.post(`/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default BookingServices;
