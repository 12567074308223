import { IMemberPoint } from "Interfaces/simulator-bay/User";
import { Link } from "react-router-dom";
import { UserContext } from "contexts/simulator-bay/UserContext";
import dayjs from "dayjs";
import { useContext } from "react";

interface Props {
  memberPoints: IMemberPoint[];
}

const CreditBox: React.FC<Props> = ({ memberPoints }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="bg-white rounded-[18px] mb-[20px] px-[13px] py-[14px] drop-shadow-[0_0px_40px_rgba(43,43,43,0.1)]">
      <div>
        <div className="flex items-end justify-between p-5 bg-white rounded-[18px] drop-shadow-[0_0px_20px_rgba(43,43,43,0.1)]">
          <p className="label-b">Available credits</p>
          <p className="title text-red-btn">
            {user?.credits.toLocaleString()}{" "}
            <span className="label-b text-black">credits</span>
          </p>
        </div>
        <div className="p-5 space-y-[10px]">
          <div className="flex items-end justify-between">
            <span className="text-[12px] font-bold">Expiring Credits</span>
            <Link to="/simulator-bay/credits">
              <span className="underline text-[10px] font-bold text-[#EF2F2C]">
                View more credits
              </span>
            </Link>
          </div>
          {memberPoints.map((p) => {
            return (
              <div className="flex items-end justify-between">
                <span className="text-[12px] font-bold">
                  {dayjs(p.expired_at).format("DD/MM/YYYY")}
                </span>
                <div className="text-[12px] font-bold">
                  <span className="text-[#EF2F2C] mr-[5px]">
                    {p.current_point}
                  </span>
                  <span>Credits</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {user?.credits && user.credits > 0 ? (
        <Link to={"/simulator-bay/reserve-simulator"}>
          <div className="flex flex-col items-center py-[20px] bg-red-btn rounded-[18px]">
            <img
              className="w-[50px] mb-[16px]"
              src="/images/golf.png"
              alt="golf"
            />
            <p className="text-white body-b">Reserve Simulator Bay</p>
          </div>
        </Link>
      ) : (
        <div className="flex flex-col items-center py-[20px] bg-gray rounded-[18px]">
          <img
            className="w-[50px] mb-[16px]"
            src="/images/golf.png"
            alt="golf"
          />
          <p className="text-white body-b">Reserve Simulator Bay</p>
        </div>
      )}
    </div>
  );
};

export default CreditBox;
