import { ILineProfile, IUser } from "Interfaces/User";

import AuthServices from "services/Auth";
import ProfileServices from "services/Profile";
import liff from "@line/liff/dist/lib";

export const getLineProfile = async () => {
  return new Promise<ILineProfile>(async (resolve, reject) => {
    await liff
      .init({ liffId: `${process.env.REACT_APP_LIFF}` })
      .catch((err) => {
        reject(err);
      });
    if (liff.isLoggedIn()) {
      let lineProfile = await liff.getProfile();
      resolve({
        userLineID: lineProfile.userId,
        pictureUrl: lineProfile.pictureUrl || "/profile.png",
      });
    }
  });
};

export const login = async (lineID: string) => {
  return new Promise<string>(async (resolve, reject) => {
    await AuthServices.login(lineID)
      .then((res) => {
        let token: string = res.data.token;
        resolve(token);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUser = async (token: string) => {
  return new Promise<IUser>(async (resolve, reject) => {
    await ProfileServices.getProfile(token)
      .then((res) => {
        let user:IUser = res.data.data;
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
