import { useContext, useEffect, useState } from "react";

import BackButton from "components/common/BackButton";
import { Helmet } from "react-helmet";
import { IMemberPoint } from "Interfaces/simulator-bay/User";
import MemberPointServices from "services/simulator-bay/MemberPoint";
import { UserContext } from "contexts/simulator-bay/UserContext";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import withAuth from "hoc/simulator-bay/withAuth";

const Credits = () => {
  const { user, token } = useContext(UserContext);
  const navigate = useNavigate();
  const [memberPoints, setMemberPoints] = useState<IMemberPoint[]>([]);

  useEffect(() => {
    MemberPointServices.GetAll(token as string)
      .then((res) => {
        setMemberPoints(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Credits - CCK GIC</title>
      </Helmet>
      <BackButton
        onClick={() => navigate("/simulator-bay/member")}
        title="My Credits"
      />
      <div className="pt-[80px] pb-[150px] container">
        <div
          className="rounded-[18px] flex justify-between items-end p-5 mb-[25px]"
          style={{
            boxShadow: "0px 15px 40px rgba(43, 43, 43, 0.1)",
          }}
        >
          <span className="text-[12px] font-bold">Available credits</span>
          <div className="space-x-[18px]">
            <span className="text-[20px] font-bold text-[#EF2F2C]">
              {user?.credits.toLocaleString()}
            </span>
            <span className="text-[12px] font-bold">Credits</span>
          </div>
        </div>
        {memberPoints.length > 0 ? (
          <div
            className="rounded-[18px] p-5"
            style={{
              boxShadow: "0px 15px 40px rgba(43, 43, 43, 0.1)",
            }}
          >
            <p className="text-[14px] font-bold text-center mb-[20px]">
              All Credits
            </p>
            <div className="flex justify-between mb-[10px]">
              <span className="text-[12px] font-bold">Expired date</span>
              <span className="text-[12px] font-bold">Balance Credits</span>
            </div>
            <div className="space-y-[5px]">
              {memberPoints.map((p) => {
                return (
                  <div className="flex items-end justify-between">
                    <span className="text-[12px] font-bold">
                      {dayjs(p.expired_at).format("DD/MM/YYYY")}
                    </span>
                    <div className="space-x-[5px]">
                      <span className="text-[12px] font-bold text-[#EF2F2C]">
                        {p.current_point.toLocaleString()}
                      </span>
                      <span className="text-[12px] font-bold">Credits</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withAuth(Credits);
