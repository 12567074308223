import { useContext, useEffect, useState } from "react";

import HistoryCard from "./HistoryCard";
import { IRewardRedeem } from "Interfaces/Reward";
import LoadingSection from "components/LoadingSection";
import RewardServices from "services/Reward";
import { UserContext } from "contexts/UserContext";

const History = () => {
  const [histories, setHistories] = useState<IRewardRedeem[] | null>(null);
  const { token } = useContext(UserContext);

  useEffect(() => {
    RewardServices.getHistory(token as string).then((res) => {
      setHistories(res.data.data);
    });
  }, []);

  return (
    <div>
      <div className="space-y-[16px]">
        {histories ? (
          <>
            {histories.length > 0 ? (
              <>
                {histories.map((history: IRewardRedeem) => {
                  return <HistoryCard history={history} />;
                })}
              </>
            ) : (
              "ไม่มีประวัติ"
            )}
          </>
        ) : (
          <LoadingSection />
        )}
      </div>
      <p className="caption-m text-gray text-center mt-[30px]">
        แสดงประวัติรางวัลย้อนหลัง 90 วันล่าสุด
      </p>
    </div>
  );
};

export default History;
