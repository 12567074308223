import { Avatar, AvatarGroup } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { IRanking } from "Interfaces/Ranking";
import RankingServices from "services/Ranking";
import { UserContext } from "contexts/UserContext";
import withAuth from "store/withAuth";

const Ranking = () => {
  const { profile, user, token } = useContext(UserContext);

  const [ranking, setRanking] = useState<IRanking | null>(null);

  const year = new Date().getFullYear() + 543;

  let isRank1 =
    ranking &&
    ranking.rankings.length > 0 &&
    ranking.rankings[0].rank === ranking.my_rank.rank;
  let isRank2 =
    ranking &&
    ranking.rankings.length > 1 &&
    ranking.rankings[1].rank === ranking.my_rank.rank;
  let isRank3 =
    ranking &&
    ranking.rankings.length > 2 &&
    ranking.rankings[2].rank === ranking.my_rank.rank;

  useEffect(() => {
    RankingServices.getRanking(token as string).then((res) => {
      setRanking(res.data.data);
    });
  }, []);

  return (
    <div className="pt-[30px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ranking - Only Golf</title>
      </Helmet>
      <div className="container fixed top-0 bg-white z-[999]">
        <p className="title mb-[10px]">30 อันดับยอดสั่งซื้อประจำปี {year}</p>
        <p className="caption-b text-gray mb-[18px]">
          ยอดสั่งซื้อสะสมประจำปีจะถูกนับจนถึงวันที่ 31 ธันวาคม {year}{" "}
          และจะเริ่มนับยอดสะสมประจำปีใหม่ในปีถัดไป
        </p>
        <div className="grid grid-cols-3 mb-[5px]">
          {/* ---------- start rank 2 ---------- */}
          <div
            className={`grid content-end rounded-lg text-center py-2 ${
              isRank2 ? "bg-light-green/20" : ""
            }`}
          >
            <div className="flex items-center justify-center w-[65px] h-[65px] mx-auto mb-[7px] rounded-[50%] bg-gray">
              <img
                className="w-[41px] h-[41px]"
                src="/images/medal.png"
                alt="medal"
              />
            </div>
            <p className="caption-b mb-[5px]">อันดับ 2</p>
            <div className="mx-auto mb-[5px]">
              <AvatarComp
                members={
                  (ranking &&
                    ranking.rankings.length > 1 &&
                    ranking.rankings[1].members) ||
                  1
                }
                isMyRank={isRank2 || false}
                profilePicture={profile?.pictureUrl || null}
              />
            </div>
            <NameComp
              isMyRank={isRank2 || false}
              name={`${user?.first_name} ${user?.last_name}`}
            />
            <p className="remark-b">
              {ranking && ranking.rankings.length > 1
                ? ranking?.rankings[1].total_spend?.toLocaleString()
                : 0}{" "}
              <span className="text-light-green">THB</span>
            </p>
          </div>
          {/* ---------- end rank 2 ---------- */}
          {/* ---------- start rank 1 ---------- */}
          <div
            className={`grid content-start rounded-lg text-center py-2 ${
              isRank1 ? "bg-light-green/20" : ""
            }`}
          >
            <div className="flex items-center justify-center w-[80px] h-[80px] mx-auto mb-[7px] rounded-[50%] bg-yellow">
              <img
                className="w-[41px] h-[41px]"
                src="/images/medal.png"
                alt="medal"
              />
            </div>
            <p className="caption-b mb-[5px]">อันดับ 1</p>
            <div className="mx-auto mb-[5px]">
              <AvatarComp
                members={
                  (ranking &&
                    ranking.rankings.length > 0 &&
                    ranking.rankings[0].members) ||
                  1
                }
                isMyRank={isRank1 || false}
                profilePicture={profile?.pictureUrl || null}
              />
            </div>
            <NameComp
              isMyRank={isRank1 || false}
              name={`${user?.first_name} ${user?.last_name}`}
            />
            <p className="remark-b">
              {ranking && ranking.rankings.length > 0
                ? ranking.rankings[0].total_spend?.toLocaleString()
                : 0}{" "}
              <span className="text-light-green">THB</span>
            </p>
          </div>
          {/* ---------- end rank 1 ---------- */}
          {/* ---------- start rank 3 ---------- */}
          <div
            className={`grid content-end rounded-lg text-center py-2 ${
              isRank3 ? "bg-light-green/20" : ""
            }`}
          >
            <div className="flex items-center justify-center w-[65px] h-[65px] mx-auto mb-[7px] rounded-[50%] bg-brown">
              <img
                className="w-[41px] h-[41px]"
                src="/images/medal.png"
                alt="medal"
              />
            </div>
            <p className="caption-b mb-[5px]">อันดับ 3</p>
            <div className="mx-auto mb-[5px]">
              <AvatarComp
                members={
                  (ranking &&
                    ranking.rankings.length > 2 &&
                    ranking.rankings[2].members) ||
                  1
                }
                isMyRank={isRank3 || false}
                profilePicture={profile?.pictureUrl || null}
              />
            </div>
            <NameComp
              isMyRank={isRank3 || false}
              name={`${user?.first_name} ${user?.last_name}`}
            />
            <p className="remark-b">
              {ranking && ranking.rankings.length > 2
                ? ranking.rankings[2].total_spend?.toLocaleString()
                : 0}{" "}
              <span className="text-light-green">THB</span>
            </p>
          </div>
          {/* ---------- end rank 3 ---------- */}
        </div>
      </div>
      {ranking && ranking.rankings.length > 3 ? (
        <div className="pt-[270px] pb-[120px]">
          {ranking.rankings
            .slice(3, ranking.rankings.length)
            .map((r, index) => {
              let isMyRank = r.rank === ranking.my_rank.rank;
              return (
                <div
                  key={index}
                  className={`py-[8px] ${isMyRank ? "bg-light-green/20" : ""}`}
                >
                  <div className="container">
                    <div className="grid grid-cols-8 items-center justify-between">
                      <div className="col-span-1 justify-self-center">
                        <p className="caption-b">{r.rank}</p>
                      </div>
                      <div className="col-span-2 justify-self-start">
                        <AvatarComp
                          members={r.members}
                          isMyRank={isMyRank}
                          profilePicture={profile?.pictureUrl || null}
                        />
                      </div>
                      <div className="col-span-3">
                        <NameComp
                          isMyRank={isMyRank}
                          name={`${user?.first_name} ${user?.last_name}`}
                        />
                      </div>
                      <div className="col-span-2 justify-self-end">
                        <p className="remark-b">
                          {r.total_spend.toLocaleString()}{" "}
                          <span className="text-light-green">THB</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
      <div className="fixed bottom-0 left-0 py-[20px] rounded-t-[20px] bg-white drop-shadow-sm w-full container">
        <p className="caption-b mb-[8px]">ยอดสั่งซื้อสะสมประจำปีของฉัน</p>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-[15px]">
            <p className="caption-b">
              {ranking?.my_rank.rank === 0 ? "-" : ranking?.my_rank.rank}
            </p>
            <img
              className="w-[25px] aspect-square rounded-[50%]"
              src={profile?.pictureUrl || "/profile.png"}
              alt="profile"
            />
            <p className="caption-b">
              {user?.first_name} {user?.last_name}
            </p>
          </div>
          <p className="remark-b">
            {ranking?.my_rank.total_spend.toLocaleString()}{" "}
            <span className="text-light-green">THB</span>
          </p>
        </div>
      </div>
    </div>
  );
};

interface AvatarCompProps {
  members: number;
  isMyRank: boolean;
  profilePicture: string | null;
}

const AvatarComp: React.FC<AvatarCompProps> = ({
  members,
  isMyRank,
  profilePicture,
}) => {
  return (
    <>
      {members > 1 ? (
        <AvatarGroup
          total={members}
          sx={{
            "& .MuiAvatar-root": {
              width: 25,
              height: 25,
              fontSize: 10,
              borderWidth: 0,
            },
          }}
        >
          <Avatar
            src={isMyRank && profilePicture ? profilePicture : "/profile.png"}
            alt="profile"
            sx={{ width: 25, height: 25 }}
          />
          {Array(members - 1).map((_, index) => (
            <Avatar
              alt={`Member ${index + 2}`}
              src="/profile.png"
              sx={{ width: 25, height: 25 }}
            />
          ))}
        </AvatarGroup>
      ) : (
        <Avatar
          src={isMyRank && profilePicture ? profilePicture : "/profile.png"}
          alt="profile"
          sx={{ width: 25, height: 25 }}
        />
      )}
    </>
  );
};

interface NameCompProps {
  isMyRank: boolean;
  name: string;
}
const NameComp: React.FC<NameCompProps> = ({ isMyRank, name }) => {
  return (
    <p className={`caption-b ${isMyRank ? "" : "text-gray"}`}>
      {isMyRank
        ? name.length > 16
          ? `${name.substring(0, 14)}...`
          : name
        : "Only Golf Member"}
    </p>
  );
};

export default withAuth(Ranking);
