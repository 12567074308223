import React, { useContext, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";

import { Helmet } from "react-helmet";
import { ICreateBooking } from "Interfaces/simulator-bay/Booking";
import ReserveInfo from "components/reserveSimulatorTraining/ReserveInfo";
import Summary from "components/reserveSimulatorTraining/Summary";
import { UserContext } from "contexts/simulator-bay/UserContext";
import dayjs from "dayjs";
import withAuth from "hoc/simulator-bay/withAuth";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2C7AEF",
    },
  },
});

export const ReserveSimulatorTrainingContext = React.createContext({
  step: 1,
  input: {
    first_name: "",
    last_name: "",
    email: "",
    tel: "",
    number_of_bay: 0,
    start_time: "",
    booking_date: dayjs(new Date()).format("YYYY-MM-DD"),
    total_hour: 0,
  },
  handleInput: (name: string, value: any) => {},
  onChangeStep: (stepProps: number) => {},
});

const ReserveSimulatorTraining = () => {
  const { profile, user } = useContext(UserContext);
  const [step, setStep] = useState<number>(1);
  const [input, setInput] = useState<ICreateBooking>({
    first_name: user?.first_name as string,
    last_name: user?.last_name as string,
    email: user?.email as string,
    tel: user?.tel as string,
    number_of_bay: 1,
    start_time: "",
    booking_date: dayjs(new Date()).format("YYYY-MM-DD"),
    total_hour: 0,
  });

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <Summary />;
      default:
        return <ReserveInfo />;
    }
  }, [step]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking for training - CCK GIC</title>
      </Helmet>
      <ReserveSimulatorTrainingContext.Provider
        value={{
          step,
          input,
          handleInput,
          onChangeStep,
        }}
      >
        <ThemeProvider theme={theme}>{StepComponent}</ThemeProvider>
      </ReserveSimulatorTrainingContext.Provider>
    </>
  );
};

export default withAuth(ReserveSimulatorTraining);
