import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="container pt-[80px] text-center">
      <img
        className="mx-auto mb-[80px]"
        src="/images/check.png"
        alt="check-icon"
      />
      <p className="title">การทำรายการสำเร็จ</p>
      <p className="label-m mb-[135px] text-gray">แก้ไขข้อมูลส่วนตัวของผู้ใช้สำเร็จ</p>
      <Link to="/member">
        <button
          className="bg-light-green text-white w-full h-[45px] rounded-lg"
          type="button"
        >
          กลับสู่หน้าหลัก
        </button>
      </Link>
    </div>
  );
};

export default Success;
