import { FaFacebook, FaGlobe } from "react-icons/fa";

import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div className="pt-[32px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Only Golf</title>
      </Helmet>
      <img
        className="mx-auto mb-[16px]"
        src="/images/logo-img.png"
        alt="logo"
      />
      <div className="text-center mb-[32px]">
        <p className="title text-black mb-[10px]">Only Golf Thailand</p>
        <p className="label-m">
          จำหน่ายอุปกรณ์และ Accessories
          <br />
          สำหรับกีฬากอล์ฟทุกชนิด
        </p>
      </div>
      <a
        href="https://www.onlygolfthailand.com/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="flex items-center mx-auto mb-[16px] w-[280px] h-[70px] pl-[30px] bg-light-green rounded-[15px]">
          <FaGlobe className="text-[40px] text-white mr-[30px]" />
          <p className="title text-white">Website</p>
        </button>
      </a>
      <a
        href="https://www.facebook.com/OnlyGolfThailand"
        target="_blank"
        rel="noreferrer"
      >
        <button className="flex items-center mx-auto w-[280px] h-[70px] pl-[30px] bg-light-green rounded-[15px]">
          <FaFacebook className="text-[40px] text-white mr-[30px]" />
          <p className="title text-white">Facebook</p>
        </button>
      </a>
    </div>
  );
};

export default ContactUs;
