import axios from "axios";

const bayService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-bay`,
});

const BayServices = {
  getAllActive: (token: string, booking_date: string) =>
  bayService.get(`/active?bookingDate=${booking_date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default BayServices;
