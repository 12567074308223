import TextFieldMui, { TextFieldProps } from "@mui/material/TextField";

const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextFieldMui
      variant="standard"
      sx={{
        "& .MuiInputBase-input": {
          paddingBottom: 0,
          paddingTop: "8px",
          fontSize: 16,
        },
        "& .MuiFormLabel-root": {
          color: "#D9D9D9",
          fontSize: 18,
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#D9D9D9",
          fontSize: 18,
        },
        "& .MuiInput-underline": {
          borderBottomColor: "#D9D9D9",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "#D9D9D9",
        },
      }}
      {...props}
    />
  );
};

export default TextField;
