import axios from "axios";

const authService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/auth`,
});

const AuthServices = {
  register: (data: {
    line_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    email: string;
    tel: string;
    birth_date: string;
    bank_id?: number;
    bank_account_name?: string;
    bank_account_number?: string;
  }) => authService.post(`/register`, data),
  login: (line_id: string) => authService.post("/login", { line_id: line_id }),
  check_phone_number: (tel: string) => authService.get(`/check-member/${tel}`),
  sync_line: (data: {
    id: number,
    line_id: string
  }) => authService.put(`/sync-line`, data),
};

export default AuthServices;
