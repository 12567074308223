import { useContext, useEffect, useMemo, useState } from "react";

import CreditBox from "components/simulatorBay/member/CreditBox";
import { Helmet } from "react-helmet";
import { IMemberPoint } from "Interfaces/simulator-bay/User";
import Loading from "components/Loading";
import MemberPointServices from "services/simulator-bay/MemberPoint";
import Sidebar from "components/simulatorBay/Sidebar";
import TrainingBox from "components/simulatorBay/member/TrainingBox";
import { UserContext } from "contexts/simulator-bay/UserContext";
import { useSearchParams } from "react-router-dom";
import withAuth from "hoc/simulator-bay/withAuth";

const Member = () => {
  const { profile, user, token } = useContext(UserContext);
  const [memberPoints, setMemberPoints] = useState<IMemberPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let [searchParams, setSearchParams] = useSearchParams();

  let isTraining = useMemo(() => {
    let type = searchParams.get("type");
    return type === "training";
  }, [searchParams]);

  useEffect(() => {
    MemberPointServices.GetAll(token as string)
      .then((res) => {
        setMemberPoints(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading color={isTraining ? "info" : "error"} />;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Member - CCK GIC</title>
      </Helmet>
      <div
        className={`${
          isTraining ? "bg-heavy-blue" : "bg-red-primary"
        } min-h-screen overflow-x-hidden`}
      >
        <Sidebar />
        <div className="relative bg-white rounded-t-[35px] min-h-[calc(100vh_-_75px)] pb-[70px]">
          <div className="container">
            <img
              src={profile?.pictureUrl || "/profile.png"}
              className="absolute top-[-37.5px] left-[157.5px] w-[75px] h-[75px] border-4 border-white rounded-full"
              alt="profile"
            />
            <p className="pt-[45px] mb-[20px] title text-center">
              {user?.first_name} {user?.last_name}
            </p>
            {isTraining ? (
              <TrainingBox />
            ) : (
              <CreditBox memberPoints={memberPoints} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(Member);
