import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useContext, useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BackButton from "components/common/BackButton";
import BayServices from "services/simulator-bay/Bay";
import { Dayjs } from "dayjs";
import { IBay } from "Interfaces/simulator-bay/Booking";
import { ITimeSlot } from "Interfaces/simulator-bay/TimeSlot";
import { ReserveSimulatorContext } from "pages/simulator-bay/ReserveSimulator";
import TimeSlotServices from "services/simulator-bay/TimeSlot";
import { UserContext } from "contexts/simulator-bay/UserContext";
import { formatPhoneNumber } from "util/common";

const ReserveInfo = () => {
  const { token, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [bays, setBays] = useState<IBay[]>([]);
  const [slotTimes, setSlotTimes] = useState<ITimeSlot[]>([]);
  const [error, setError] = useState<string>("");
  const [errorCredits, setErrorCredits] = useState<string>("");

  const [totalHours, setTotalHours] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const { onChangeStep, input, handleInput } = useContext(
    ReserveSimulatorContext
  );

  useEffect(() => {
    BayServices.getAllActive(token as string, input.booking_date).then((res) => {
      setBays(res.data.data);
      handleInput("number_of_bay", 1);
    });
  }, [input.booking_date]);

  useEffect(() => {
    if (input.booking_date && input.number_of_bay) {
      setError("");
      setErrorCredits("")
      handleInput("start_time", "");
      handleInput("total_hour", 0);
      TimeSlotServices.getAll(
        token as string,
        input.booking_date,
        input.number_of_bay
      ).then((res) => {
        let slotTimesData: ITimeSlot[] = res.data.data;
        if (slotTimesData.length === 0) {
          setError("Today is fully booked");
        }
        setSlotTimes(slotTimesData);
      });
    }
  }, [token, input.number_of_bay, input.booking_date]);

  useEffect(() => {
    if (slotTimes.length > 0 && input.start_time) {
      setError("");
      let totalHour = 0;
      let nowEndTime = "";
      slotTimes.forEach((timeSlot, i) => {
        if (timeSlot.time_start === input.start_time) {
          totalHour += 1;
          nowEndTime = timeSlot.time_end;
        }
        if (nowEndTime === timeSlot.time_start) {
          totalHour += 1;
          nowEndTime = timeSlot.time_end;
        }
      });
      let hourOptions = [];
      for (let i = 0; i < totalHour; i++) {
        hourOptions.push({
          label: i + 1 + "",
          value: i + 1,
        });
      }
      // let hourSelected = hourOptions.filter(
      //   (h) => h.value === input.total_hour
      // );
      setTotalHours(hourOptions);
    } else {
      setTotalHours([]);
    }
  }, [input.start_time, slotTimes]);

  useEffect(() => {
    if (input.total_hour && input.number_of_bay && user?.credits) {
      setErrorCredits("");
      if (input.total_hour * input.number_of_bay > (user.credits as number)) {
        setErrorCredits("Insufficient credit for booking");
      }
    } else {
      setErrorCredits("");
    }
  }, [user?.credits, input.total_hour, input.number_of_bay]);
  
  return (
    <>
      <BackButton
        onClick={() => navigate("/simulator-bay/member")}
        title="Reserve simulator"
      />
      <div className="container pt-[80px] pb-[150px]">
        <Box
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
        >
          <>
            <TextField
              required
              id="first_name"
              label="First name"
              type="text"
              name="name"
              onChange={(e) => handleInput("first_name", e.target.value)}
              value={input.first_name}
              disabled
            />
            <Box sx={{ height: 20 }} />
            <TextField
              required
              id="last_name"
              label="Last name"
              type="text"
              name="lastname"
              onChange={(e) => handleInput("last_name", e.target.value)}
              value={input.last_name}
              disabled
            />
            <Box sx={{ height: 20 }} />
            <TextField
              required
              id="telno"
              label="Phone number"
              type="tel"
              name="telno"
              inputProps={{
                maxLength: 12,
              }}
              onChange={(e) => {
                handleInput("tel", e.target.value.replaceAll("-", ""));
              }}
              value={formatPhoneNumber(input.tel)}
              disabled
            />
            <Box sx={{ height: 20 }} />
            <TextField
              id="email"
              label="Email"
              type="email"
              name="email"
              onChange={(e) => handleInput("email", e.target.value)}
              value={input.email}
              disabled
            />
            <Box sx={{ height: 20 }} />
            {bays.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id="select-bay">Bay qty. *</InputLabel>
                <Select
                  labelId="select-bay"
                  id="select-bay-id"
                  name="number_of_bay"
                  value={input.number_of_bay}
                  label="Bay *"
                  onChange={(e) => handleInput("number_of_bay", e.target.value)}
                >
                  {bays.map((b, index) => {
                    return <MenuItem value={index + 1}>{index + 1}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : null}
            <Box sx={{ height: 20 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label={"Booking date *"}
                inputFormat="YYYY-MM-DD"
                value={input.booking_date}
                minDate={new Date() as any}
                onChange={(newValue: Dayjs | null) =>
                  handleInput("booking_date", newValue?.format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} value={input.booking_date} />
                )}
              />
            </LocalizationProvider>
            {slotTimes.length > 0 ? (
              <>
                <Box sx={{ height: 20 }} />
                <FormControl fullWidth>
                  <InputLabel id="select-start-time">Start time *</InputLabel>
                  <Select
                    labelId="select-start-time"
                    id="start_time"
                    name="start_time"
                    value={input.start_time}
                    label="Start time *"
                    onChange={(e) => handleInput("start_time", e.target.value)}
                  >
                    {slotTimes.map((st) => {
                      return (
                        <MenuItem value={st.time_start}>
                          {st.time_start.substring(0, 5)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : null}
            {totalHours.length > 0 ? (
              <>
                <Box sx={{ height: 20 }} />
                <FormControl fullWidth>
                  <InputLabel id="select-total-hour">Total hour *</InputLabel>
                  <Select
                    labelId="select-total-hour"
                    id="total_hour"
                    name="total_hour"
                    value={input.total_hour}
                    label="Total hour *"
                    onChange={(e) => handleInput("total_hour", e.target.value)}
                  >
                    {totalHours.map((h) => {
                      return <MenuItem value={h.value}>{h.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </>
            ) : null}
            {error || errorCredits ? (
              <>
                <Box sx={{ height: 20 }} />
                <p className="text-[#EF2F2C] font-bold text-center p-5 border-[3px] border-[#EF2F2C] rounded-[8px]">
                  {error || errorCredits}
                </p>
              </>
            ) : null}
            <div className="fixed left-0 bottom-0 py-[35px] bg-white rounded-lg">
              <div className="container grid grid-cols-2">
                <Link to={"/simulator-bay/member"}>
                  <button
                    type="button"
                    className="text-[#EF2F2C] body-b h-[45px] w-full"
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  className="text-white bg-[#EF2F2C] disabled:bg-light-gray body-b rounded-[8px] h-[45px] w-full"
                  disabled={
                    !(
                      input.booking_date &&
                      input.number_of_bay &&
                      input.start_time &&
                      input.total_hour &&
                      input.total_hour * input.number_of_bay <=
                        (user?.credits as number)
                    )
                  }
                  onClick={() => {
                    onChangeStep(2);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </>
        </Box>
      </div>
    </>
  );
};

export default ReserveInfo;
