import { useContext, useMemo } from "react";

import AuthServices from "services/Auth";
import { Box } from "@mui/material";
import { IUser } from "Interfaces/User";
import { RegisterContext } from "pages/Register";
import Swal from "sweetalert2";
import TextField from "./TextField";
import { UserContext } from "contexts/UserContext";
import { formatPhoneNumber } from "util/common";
import { useNavigate } from "react-router-dom";

const InputPhone = () => {
  const { profile } = useContext(UserContext);
  const { onChangeStep, onSetPhoneNumber, inputPhone } =
    useContext(RegisterContext);
  const navigate = useNavigate();

  const onSubmit = () => {
    AuthServices.check_phone_number(inputPhone)
      .then((res) => {
        let user: IUser = res.data.data;
        if (user.line_id) {
          Swal.fire({
            icon: "error",
            html: "หมายเลขโทรศัพท์นี้มีในระบบแล้ว กรุณาลองอีกครั้ง",
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#F61E00",
          });
        } else {
          Swal.fire({
            icon: "question",
            html: "หมายเลขโทรศัพท์นี้มีในระบบแล้ว ต้องการลงทะเบียนใหม่ด้วยไลน์นี้ใช่ หรือไม่ ?",
            showCancelButton: true,
            confirmButtonText: "ใช่",
            cancelButtonText: `<span class="text-[#EF2F2C]">ไม่</span>`,
            reverseButtons: true,
            cancelButtonColor: "white",
          }).then((result) => {
            if (result.isConfirmed) {
              AuthServices.sync_line({
                id: user.id,
                line_id: profile?.userLineID as string,
              })
                .then((res) => {
                  navigate("/member");
                })
                .catch((err) => {
                  Swal.fire({
                    icon: "error",
                    html: "บางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่",
                    confirmButtonText: "ตกลง",
                    confirmButtonColor: "#F61E00",
                  });
                });
            }
          });
        }
      })
      .catch(() => {
        onChangeStep(3);
      });
  };

  const handleInput = (e: any) => {
    const phoneNumber: string = e.target.value.replace(/[^\d]/g, "");
    if (phoneNumber.length <= 10) {
      onSetPhoneNumber(phoneNumber);
    }
  };

  const isFormatCorrect = useMemo(() => {
    if (inputPhone.length !== 10) {
      return false;
    } else {
      let startPhone = inputPhone.substring(0, 2);
      switch (startPhone) {
        case "06":
        case "08":
        case "09":
          return true;
        default:
          return false;
      }
    }
  }, [inputPhone]);

  return (
    <div className="pt-[80px]">
      <div className="h-[452px]">
        <div className="text-center">
          <p className="title mb-[8px]">ยืนยันตัวตนโดยเบอร์โทรศัพท์</p>
          <p className="label-m text-gray mb-[100px]">
            กรุณากรอกเบอร์โทรศัพท์ของท่าน
            <br />
            เพื่อรับรหัสสำหรับยืนยันตัวตน
          </p>
        </div>
        <Box
          className="mb-[155px]"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
        >
          <TextField
            label="เบอร์โทรศัพท์"
            type="tel"
            onChange={(e) => handleInput(e)}
            value={formatPhoneNumber(inputPhone)}
          />
        </Box>
      </div>
      <button
        className="body-b bg-light-green disabled:bg-light-gray text-white w-full h-[45px] rounded-[8px]"
        onClick={onSubmit}
        disabled={!isFormatCorrect}
      >
        ส่ง OTP
      </button>
    </div>
  );
};

export default InputPhone;
