import React, { useContext, useEffect, useState } from "react";

import AuthServices from "services/Auth";
import Autocomplete from "@mui/material/Autocomplete";
import BankServices from "services/Bank";
import Box from "@mui/material/Box";
import CustomerServices from "services/Customer";
import DatePicker from "react-mobile-datepicker";
import Field from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IBank } from "Interfaces/User";
import { ICustomer } from "Interfaces/Customer";
import Moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { RegisterContext } from "pages/Register";
import Swal from "sweetalert2";
import TextField from "./TextField";
import { UserContext } from "contexts/UserContext";
import { formatPhoneNumber } from "util/common";
import liff from "@line/liff/dist/lib";
import { useNavigate } from "react-router-dom";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const { inputPhone } = useContext(RegisterContext);

  // const [banks, setBanks] = useState<
  //   | {
  //       label: string;
  //       value: string;
  //     }[]
  //   | null
  // >(null);

  const [input, setInput] = useState<{
    name: string;
    lastname: string;
    email: string;
    tel: string;
    gender: string;
    birthDate: Date | null;
    // bank: {
    //   label: string;
    //   value: string;
    // };
    // bankAcc: string;
    // bankNo: string;
  }>({
    name: "",
    lastname: "",
    email: "",
    tel: inputPhone,
    gender: "",
    birthDate: null,
    // bank: {
    //   label: "",
    //   value: "",
    // },
    // bankAcc: "",
    // bankNo: "",
  });

  const [isReady, setIsReady] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { profile } = useContext(UserContext);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    const getCustomer = () => {
      CustomerServices.get(inputPhone)
        .then((res) => {
          let customer: ICustomer = res.data.data;
          setInput({
            ...input,
            name: customer.fname,
            lastname: customer.lname,
            email: customer.email,
            gender:
              customer.gender === "male"
                ? "M"
                : customer.gender === "female"
                ? "F"
                : "O",
            birthDate: new Date(customer.dob),
          });
          setIsReady(true);
        })
        .catch(() => {
          setIsReady(true);
        });
    };
    getCustomer();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">โปรดตรวจสอบข้อมูลก่อนยืนยันการสมัคร ต้องการยืนยันข้อมูลการสมัครสมาชิกใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      cancelButtonText: `<span class="text-light-green">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        if (profile) {
          AuthServices.register({
            line_id: profile.userLineID,
            first_name: input.name,
            last_name: input.lastname,
            gender: input.gender,
            email: input.email,
            tel: input.tel.replace(/[^\d]/g, ""),
            birth_date: Moment(input.birthDate).format("YYYY-MM-DD"),
            // bank_id: parseInt(input.bank.value),
            // bank_account_name: input.bankAcc,
            // bank_account_number: input.bankNo,
          })
            .then((res) => {
              navigate("/member");
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                html: err.response.data.message,
                confirmButtonText: "ตกลง",
                confirmButtonColor: "#F61E00",
              });
            });
        }
      }
    });
  };

  const handleCancelInfo = () => {
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">ข้อมูลที่ทำการกรอกจะไม่ถูกบันทึกในระบบ
      ต้องการออกจากการขั้นตอนการสมัครสมาชิก ใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      cancelButtonText: `<span class="text-light-green">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        liff.closeWindow();
      }
    });
  };

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSelect = (time: Date) => {
    setInput({
      ...input,
      birthDate: time,
    });
    setIsOpen(false);
  };

  if (isReady) {
    return (
      <div className="pt-[35px] pb-[150px]">
        <img
          src={profile?.pictureUrl || "/profile.png"}
          className="flex mx-auto mb-[30px] w-[100px] h-[100px] rounded-full object-cover"
          alt="profile"
        />
        <p className="heading mb-[20px]">ข้อมูลส่วนตัว</p>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="name"
            label="ชื่อจริง"
            type="text"
            name="name"
            onChange={(e) => handleInput("name", e.target.value)}
            value={input.name}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="lastname"
            label="นามสกุล"
            type="text"
            name="lastname"
            onChange={(e) => handleInput("lastname", e.target.value)}
            value={input.lastname}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="email"
            label="อีเมล"
            type="email"
            name="email"
            onChange={(e) => handleInput("email", e.target.value)}
            value={input.email}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            disabled
            required
            id="telno"
            label="เบอร์โทรศัพท์"
            type="tel"
            name="telno"
            value={formatPhoneNumber(inputPhone)}
          />
          <Box sx={{ height: 20 }} />
          <div className="mb-[25px]">
            <p className="body-b mt-[10px] mb-[15px]">เพศ*</p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="gender"
                name="gender"
                value={input.gender}
                onChange={(e) => handleInput("gender", e.target.value)}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="ชาย"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="หญิง"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel
                  value="O"
                  control={<Radio />}
                  label="ไม่ระบุ"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="mb-[35px]">
            <button
              type="button"
              className={`relative select-btn w-full text-left ${
                input.birthDate ? "text-black" : "text-light-gray"
              } `}
              onClick={handleClick}
            >
              <p
                className={`${
                  input.birthDate
                    ? "absolute top-[-8px] ml-[11px] px-[5px] text-light-gray caption-m bg-white"
                    : "hidden"
                }`}
              >
                วัน/เดือน/ปี (ค.ศ.) เกิด
              </p>
              <div className="p-[15px] border-2 border-light-gray rounded">
                {input.birthDate
                  ? Moment(input.birthDate).format("DD/MM/YYYY")
                  : "วัน/เดือน/ปี (ค.ศ.) เกิด"}
              </div>
            </button>
            <DatePicker
              confirmText="ยืนยัน"
              cancelText="ยกเลิก"
              customHeader={<></>}
              dateConfig={{
                date: {
                  format: "DD",
                  caption: "Day",
                  step: 1,
                },
                month: {
                  format: "MM",
                  caption: "Mon",
                  step: 1,
                },
                year: {
                  format: "YYYY",
                  caption: "Year",
                  step: 1,
                },
              }}
              theme="ios"
              value={input.birthDate || new Date()}
              isOpen={isOpen}
              onSelect={handleSelect}
              onCancel={handleCancel}
            />
          </div>
          {/* <div className="mb-[35px]">
            <p className="heading mb-[20px]">ข้อมูลสำหรับการรับเงินรางวัล</p>
            {banks !== null && (
              <div className="mb-[10px]">
                <Autocomplete
                  value={input.bank}
                  onChange={(event, newValue) => {
                    handleInput("bank", newValue);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={banks}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <Field {...params} label="เลือกธนาคาร" />
                  )}
                />
              </div>
            )}
          </div>
          <TextField
            id="standard-basic"
            label="ชื่อบัญชีธนาคาร"
            type="text"
            onChange={(e) => handleInput("bankAcc", e.target.value)}
            value={input.bankAcc}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            id="standard-basic"
            label="เลขบัญชีธนาคาร"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={(e) => handleInput("bankNo", e.target.value)}
            value={input.bankNo}
          /> */}
          <div className="fixed bottom-0 left-0 py-[35px] bg-white">
            <div className="container grid grid-cols-2">
              <button
                type="button"
                className="text-light-green body-b"
                onClick={handleCancelInfo}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="text-white bg-light-green disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
                disabled={
                  !(
                    input.name &&
                    input.lastname &&
                    input.email &&
                    input.tel &&
                    input.gender &&
                    input.birthDate
                  )
                }
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Box>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PersonalInfo;
