import * as React from "react";

import { FaBars, FaFileAlt, FaShieldAlt, FaUserCircle } from "react-icons/fa";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { IoCloseSharp } from "react-icons/io5";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{ backgroundColor: "#13BD57", height: "50px" }}
          >
            <IoCloseSharp className="text-white text-[25px] mr-[15px]" />

            <ListItemText>
              <p className="label-b text-white">เมนูเพิ่มเติม</p>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {[
          {
            icon: (
              <FaUserCircle className="text-light-green text-[25px] mr-[15px]" />
            ),
            onClick: () => navigate("/edit-profile"),
            label: "ข้อมูลส่วนตัว",
          },
          {
            icon: (
              <FaFileAlt className="text-light-green text-[25px] mr-[15px]" />
            ),
            onClick: () => navigate("/term-and-condition"),
            label: "ข้อกำหนดและเงื่อนไข",
          },
          {
            icon: (
              <FaShieldAlt className="text-light-green text-[25px] mr-[15px]" />
            ),
            onClick: () => navigate("/pdpa-policy"),
            label: "นโยบายความเป็นส่วนตัว",
          },
        ].map((menu, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={menu.onClick} sx={{ height: "50px" }}>
              {menu.icon}
              <ListItemText>
                <p className="label-b text-black">{menu.label}</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="container">
      <div className="flex justify-between py-[20px]">
        <img className="h-[35px]" src="/images/logo.png" alt="onlygolf-logo" />
        <Button onClick={toggleDrawer(true)}>
          <FaBars className="text-[25px] text-white" />
        </Button>
      </div>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
