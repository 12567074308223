import * as React from "react";

import { FaBars, FaFileAlt, FaShieldAlt, FaUserCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { IoCloseSharp } from "react-icons/io5";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const Sidebar = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  let isTraining = React.useMemo(() => {
    let type = searchParams.get("type");
    return type === "training";
  }, [searchParams]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{ backgroundColor: "#EF2F2C", height: "50px" }}
          >
            <IoCloseSharp className="text-white text-[25px] mr-[15px]" />
            <ListItemText>
              <p className="label-b text-white">Menu</p>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {[
          {
            icon: (
              <FaUserCircle className="text-red-btn text-[25px] mr-[15px]" />
            ),
            onClick: () => navigate(`/simulator-bay/edit-profile${isTraining ? "?type=training" : ""}`),
            label: "Edit Information",
          },
          {
            icon: <FaFileAlt className="text-red-btn text-[25px] mr-[15px]" />,
            onClick: () => navigate(`/simulator-bay/terms-and-conditions${isTraining ? "?type=training" : ""}`),
            label: "Terms & Conditions",
          },
          {
            icon: (
              <FaShieldAlt className="text-red-btn text-[25px] mr-[15px]" />
            ),
            onClick: () => navigate(`/simulator-bay/pdpa-policy${isTraining ? "?type=training" : ""}`),
            label: "PDPA",
          },
        ].map((menu, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={menu.onClick} sx={{ height: "50px" }}>
              {menu.icon}
              <ListItemText>
                <p className="label-b text-black">{menu.label}</p>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="container">
      <div className="flex justify-end py-[20px]">
        <Button onClick={toggleDrawer(true)}>
          <FaBars className="text-[25px] text-white" />
        </Button>
      </div>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
