import { useContext, useEffect, useState } from "react";

import BackButton from "components/common/BackButton";
import { IOtpResponse } from "Interfaces/Otp";
import OtpInput from "react-otp-input";
import OtpServices from "services/simulator-bay/Otp";
import { SimulatorBayContext } from "pages/simulator-bay/register";
import Swal from "sweetalert2";

const VerifyCode = () => {
  const { inputPhone, onChangeStep } = useContext(SimulatorBayContext);

  const [otp, setOtp] = useState<string>("");
  const [verify, setVerify] = useState<IOtpResponse | null>(null);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    onSendOtp();
  }, []);

  useEffect(() => {
    let countTimeout: any;
    if (time && verify) {
      countTimeout = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(countTimeout);
    };
  }, [time, verify]);

  const onSendOtp = () => {
    if (
      process.env.REACT_APP_MODE === "development" ||
      process.env.REACT_APP_MODE === "staging"
    ) {
      setVerify({
        token: "",
        refno: "RERER",
        status: "success",
      });
      setTime(60);
    } else {
      OtpServices.send(inputPhone)
        .then((res) => {
          setVerify(res.data.data);
          setTime(60);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            html: err.response.data.message,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#F61E00",
          });
        });
    }
  };

  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const handleVerifyCode = () => {
    if (
      process.env.REACT_APP_MODE === "development" ||
      process.env.REACT_APP_MODE === "staging"
    ) {
      if (otp === "1111") {
        onChangeStep(5);
      } else {
        Swal.fire({
          icon: "error",
          html: "รหัส OTP ไม่ถูกต้อง",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#F61E00",
        });
      }
    } else {
      OtpServices.verify(verify?.token as string, otp)
        .then(() => {
          onChangeStep(5);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            html: err.response.data.message,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#F61E00",
          });
        });
    }
  };

  if (verify) {
    return (
      <div>
      <BackButton onClick={() => onChangeStep(3)} title="OTP Verification" />
        <div className="pt-[80px]">
          <div className="text-left">
            <p className="title mb-[8px]">Enter OTP code</p>
            <p className="label-m text-gray mb-[65px]">
              Please enter the OTP code that was sent to your
              <br />
              phone number
            </p>
          </div>
          <div className="mb-[20px]">
            <p className="label-m mb-[5px]">Ref No. {verify.refno}</p>
            <p className="remark text-red  mb-[10px]">
              *OTP is valid for 15 minutes.
            </p>
            <OtpInput
              containerStyle={{
                justifyContent: "space-between",
              }}
              inputStyle={{
                width: "55px",
                height: "55px",
                border: "2px solid #D9D9D9",
                borderRadius: "8px",
              }}
              focusStyle={{
                outline: "none",
                border: "2px solid #F61E00",
              }}
              value={otp}
              onChange={handleChange}
              numInputs={4}
              isInputNum
            />
          </div>
          <button
            className="disabled:text-light-gray text-red flex mx-auto"
            disabled={!(time === 0)}
            onClick={() => {
              onSendOtp();
            }}
          >
            {time === 0
              ? "Resend OTP"
              : `Not received OTP? Please try again within ${time} seconds.`}
          </button>
          <div className="fixed left-0 bottom-[30px] w-full px-[35px]">
            <button
              className="body-b bg-red-btn text-white disabled:bg-light-gray w-full h-[45px] mb-[15px] rounded-[8px]"
              onClick={handleVerifyCode}
              disabled={!(otp.length === 4)}
            >
              Confirm
            </button>
            <button
              className="text-gray flex mx-auto"
              onClick={() => {
                onChangeStep(3);
              }}
            >
              Change phone number
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default VerifyCode;
