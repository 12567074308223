import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Pdpa = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PDPA - Only Golf</title>
      </Helmet>
      <div className="pt-[32px] pb-[150px] container">
        <p className="title text-center mb-[5px]">
          นโยบายคุ้มครองข้อมูลส่วนบุคคล
        </p>
        <p className="body-b text-center mb-[10px]">บริษัท โอนลี่กอล์ฟ จำกัด</p>
        <p className="label-b mb-[10px]">
          นโยบายความเป็นส่วนตัวฉบับนี้ มีผลบังคับใช้ตั้งแต่ <br />1 มิถุนายน
          2565
        </p>
        <p className="label-m mb-[10px]">
          บริษัท โอนลี่กอล์ฟ จำกัด
          ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล
          และมีความยินดีเป็นอย่างยิ่งที่ท่านให้ความสนใจในการใช้บริการของเรา
          เราจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้นเพื่อบังคับใช้กับเจ้าของข้อมูลส่วนบุคคลซึ่งใช้บริการต่าง
          ๆ ได้แก่ เว็บไซต์ โซเชียลมีเดีย (Social Media) และช่องทางอื่น ๆ
          ในอนาคต โดยมีหลักเกณฑ์ กลไก มาตรการกำกับดูแล
          และการบริหารจัดการข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
          พ.ศ.2562 ดังรายละเอียดต่อไปนี้
        </p>
        <ol className="list-decimal label-m">
          <li className="label-b mb-[5px] inline-block">1. คำนิยาม</li>
          <ol className="mb-[10px]">
            <li>
              1.1. “บริษัท” หรือ “เรา” หมายความถึง บริษัท โอนลี่กอล์ฟ จำกัด
              และบริษัทในเครือ และให้หมายความรวมถึงบุคคลที่เรามอบหมายด้วย
            </li>
            <li>
              1.2. “ผู้ใช้งาน” หรือ “ท่าน” หมายความถึง ผู้ใช้งานบริการต่างๆ
              ของเราภายใต้ข้อกำหนดเหล่านี้
            </li>
            <li>
              1.3. “ข้อมูลส่วนบุคคล” (Personal Data) หมายความถึง ข้อมูลใด ๆ
              ที่เกี่ยวข้องกับท่านซึ่งสามารถระบุตัวตนของท่านได้โดยตรงหรือโดยอ้อม
              เราอาจ เก็บรวบรวม หรือได้มาซึ่งข้อมูลประเภทต่างๆ
              ที่อาจรวมถึงข้อมูลส่วนบุคคลของท่านโดยตรงจากท่าน
              หรือโดยอ้อมผ่านทางแหล่งที่มาอื่น ๆ
            </li>
            <li>
              1.4. “ข้อมูลที่มีความอ่อนไหว” (Sensitive Personal Data)
              หมายความถึง
              ข้อมูลส่วนบุคคลที่เป็นเรื่องละเอียดอ่อนเป็นการส่วนตัวของบุคคล
              ที่ต้องดำเนินการด้วยความระมัดระวังเป็นพิเศษเพื่อไม่ให้ถูกนำไปใช้ในการเลือกปฏิบัติอย่างไม่เป็นธรรม
            </li>
            <li>
              1.5. “ผู้ควบคุมข้อมูลส่วนบุคคล” (Data Controller) หมายความถึง
              บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจ
              เกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            2. ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม
          </li>
          <p>ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม ประกอบด้วย</p>
          <ol className="mb-[10px]">
            <li>2.1. ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล เพศ วันเกิด</li>
            <li>
              2.2. ข้อมูลการติดต่อ เช่น ที่อยู่บ้าน ที่อยู่ที่ทำงาน
              ที่อยู่เพื่อจัดส่งสินค้า เบอร์โทรศัพท์ อีเมล
            </li>
            <li>
              2.3. ข้อมูลบัญชีผู้ใช้ เช่น ชื่อผู้ใช้ รหัสผ่าน วันที่สร้างบัญชี
              ชื่อของผู้สร้างบัญชีผู้ใช้ ข้อมูลประวัติส่วนตัว
            </li>
            <li>2.4. ข้อมูลทางการเงิน เช่น ข้อมูลบัญชีธนาคาร</li>
            <li>
              2.5. บัญชีผู้ใช้และข้อมูลโซเชียลมีเดีย เช่น Facebook, Instagram,
              YouTube, บัญชีผู้ใช้ LINE, Google, เว็บไซต์ต่าง ๆ
            </li>
            <li>
              2.6. ข้อมูลการจราจรทางคอมพิวเตอร์ ได้แก่ IP address, MAC address,
              Cookie ID เราจะทำการวิเคราะห์ข้อมูลส่วนบุคคลที่ได้เก็บรวบรวม
              เพื่อให้มั่นใจว่าข้อมูลที่เราเก็บรวบรวมเป็นข้อมูลที่จำเป็นจริงๆ
              และการเก็บข้อมูลมีความปลอดภัยเพียงพอ
              เราจะไม่เก็บรวบรวมข้อมูลที่มีความอ่อนไหว สำหรับการดำเนินการตามปกติ
              ยกเว้นมีความจำเป็นต้องเก็บข้อมูลดังกล่าว
              เราจะแจ้งเจ้าของข้อมูลล่วงหน้าเพื่อขอความยินยอมก่อนการเก็บรวบรวม
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            3. แหล่งที่มาของข้อมูลที่เราเก็บรวบรวม
          </li>
          <ol className="mb-[10px]">
            <li>
              3.1. เราได้รับข้อมูลส่วนบุคคลจากท่านโดยตรง ผ่านขั้นตอนดังต่อไปนี้
            </li>
            <ol>
              <li>3.1.1. การสมัคร/ขอใช้บริการ หรือการลงทะเบียน กับเรา</li>
              <li>
                3.1.2. การสั่งซื้อสินค้าและบริการจากเว็บไซต์หรือช่องทางอื่นๆ
                ของเรา
              </li>
              <li>
                3.1.3. การทำแบบสอบถาม โดยความสมัครใจของท่าน
                หรือการตอบกลับอีเมลของท่านกับเรา
                หรือการสื่อสารอื่นใดของท่านกับเรา
              </li>
            </ol>
            <li>
              3.2. เราได้รับข้อมูลส่วนบุคคลของท่าน ผ่านบุคคลที่สาม เช่น
              Facebook, LINE หรือช่องทางอื่นๆ
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            4. วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่าน
          </li>
          <ol className="mb-[10px]">
            <li>
              4.1. เพื่อให้สามารถติดต่อสื่อสารกับท่าน ที่เกี่ยวข้องกับบริการ
              การดูแลลูกค้า การควบคุมคุณภาพในการให้บริการ
            </li>
            <li>
              4.2. เพื่อสมัครสมาชิก พิสูจน์ตัวตน ระบุตัวตน
              และรับรองตัวตนท่านหรือการยืนยันตัวตนของท่าน
            </li>
            <li>
              4.3. เพื่อให้ท่านสามารถมีส่วนร่วมในการส่งเสริมการขาย ข้อเสนอพิเศษ
              การสะสมคะแนน การแลกของรางวัล การจับรางวัล และข้อเสนอ
              การส่งเสริมการขายอื่นๆ หรือกิจกรรมอื่นใดที่เกี่ยวข้องทั้งหมด
            </li>
            <li>
              4.4. เพื่อให้สามารถส่งอีเมล หรือส่งข้อมูลข่าวสาร โปรโมชั่น
              และอื่นๆ ที่อาจเป็นประโยชน์ต่อท่าน
            </li>
            <li>4.5. เพื่อพัฒนาและปรับปรุงบริการให้ดียิ่งขึ้น</li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            5. การเปิดเผยข้อมูลส่วนบุคคล
          </li>
          <ol className="mb-[10px]">
            <li>5.1. บริษัทในเครือ</li>
            <li>
              5.2.
              บุคคลหรือบริษัทหรือหน่วยงานภายนอกที่เป็นผู้ให้บริการที่เราเป็นผู้ว่าจ้าง
            </li>
            <li>
              5.3. หน่วยงานรัฐ หน่วยงานที่บังคับใช้กฎหมาย ศาล ผู้บังคับใช้กฎหมาย
              หรือบุคคลภายนอกอื่นใดหากเราเชื่อว่ามีความจำเป็นที่จะต้องปฏิบัติหน้าที่ตามกฎหมายหรือระเบียบใดๆ
              หรือต้องคุ้มครองสิทธิของเรา สิทธิของบุคคลที่สาม
              หรือความปลอดภัยส่วนบุคคลของบุคคลใดๆ หรือเพื่อตรวจจับ ป้องกัน
              หรือแก้ไขปัญหาการทุจริต ความมั่นคง หรือความปลอดภัย
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            6. การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
          </li>
          <p className="mb-[10px]">
            เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผลเพื่อให้บรรลุตามวัตถุประสงค์ของการรับข้อมูลส่วนบุคคลนั้นมา
            และเพื่อปฏิบัติหน้าที่ของเรา ตามกฎหมายหรือระเบียบใดๆ
            เราอาจมีความจำเป็นต้องเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาที่นานขึ้นหากมีความจำเป็น
            และ / หรือ อนุญาตให้กระทำได้ตามกฎหมาย
          </p>
          <li className="label-b mb-[5px] inline-block">
            7. การใช้ข้อมูลส่วนบุคคลเพื่อการตลาด
          </li>
          <p className="mb-[10px]">
            ระหว่างที่ท่านใช้บริการของเรา อาจได้รับการส่งข้อมูลผลิตภัณฑ์
            และรายการส่งเสริมการขายใดๆ ที่คาดว่าท่านน่าจะสนใจไปให้
            โดยท่านมีสิทธิที่จะยกเลิกการรับข้อมูลดังกล่าวได้ทุกเวลา
            โดยติดต่อเราผ่านช่องทางที่เรากำหนดไว้ หากท่านยกเลิกการเป็นสมาชิก
            จะทำให้ท่านไม่ได้รับการส่งข้อมูลใดๆ ให้ท่านอีกต่อไป
          </p>
          <li className="label-b mb-[5px] inline-block">
            8. สิทธิของเจ้าของข้อมูล
          </li>
          <p className="mb-[5px]">
            สิทธิตามที่ระบุไว้ในส่วนนี้เป็นสิทธิตามกฎหมายของท่าน
            ท่านสามารถขอใช้สิทธิตามกฎหมายได้ตามเงื่อนไขที่กำหนดไว้ในกฎหมายและกระบวนการจัดการสิทธิของเรา
            สิทธิดังกล่าวมีดังต่อไปนี้
          </p>
          <ol className="mb-[10px]">
            <li>
              8.1. ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผล
              รวมถึงการเพิกถอนการจัดเก็บข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับเราได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับเรา
            </li>
            <li>
              8.2. ท่านมีสิทธิที่จะเข้าถึงข้อมูลส่วนบุคคลของท่าน
              โดยการขอให้เราจัดทำสำเนาข้อมูลส่วนบุคคลของท่านให้ท่าน
              รวมถึงเปิดเผยการได้มาซึ่งข้อมูลของท่าน ซึ่งท่านไม่ได้ยินยอมต่อเรา
            </li>
            <li>
              8.3.
              ท่านมีสิทธิแก้ไขหรือขอให้เราแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์ได้
            </li>
            <li>
              8.4. ท่านมีสิทธิที่จะลบหรือขอให้เราลบข้อมูลส่วนบุคคลของท่านได้
              ผ่านการยกเลิการเป็นสมาชิกกับทางเรา
              ซึ่งทำให้เราไม่สามารถให้บริการท่านได้ต่อไป
            </li>
            <li>
              8.5. ท่านสามารถขอให้เราระงับการใช้ข้อมูลส่วนบุคคลของท่านได้
              ท่านสามารถติดต่อเราผ่านทางที่อยู่ในส่วน "ข้อมูลการติดต่อของเรา"
              ด้านล่างนี้เพื่อขอใช้สิทธิเหล่านี้
              คำขอในการใช้สิทธิเหล่านี้ของท่านอาจถูกจำกัดโดยกฎหมายที่เกี่ยวข้อง
              ในบางกรณีที่เราอาจจำเป็นต้องปฏิเสธคำขอของท่านด้วยเหตุผลและกฎหมายบางประการ
              เช่น เหตุอันเนื่องมาจากหน้าที่ตามกฎหมาย
              หรือคำสั่งศาลทั้งนี้เราจะแจ้งให้ท่านทราบถึงเหตุผลของเรา
              หากเราจำเป็นต้องปฏิเสธคำขอของท่าน
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            9. Cookies และการใช้ Cookies
          </li>
          <ol className="mb-[10px]">
            <li>
              9.1. Cookies
              คือไฟล์ข้อมูลขนาดเล็กที่ฝังอยู่ในคอมพิวเตอร์หรืออุปกรณ์ของคุณเมื่อคุณเยี่ยมชมเว็บไซต์
              หลังจากนั้นคุกกี้จะถูกส่งกลับไปยังเว็บไซต์ต้นทางในแต่ละครั้งของการเข้าเยี่ยมชมในครั้งถัดไปหรือส่งไปยังเว็บไซต์อื่นที่จดจำคุกกี้นั้นได้
              คุกกี้เหล่านี้ถูกใช้เพื่อทำให้เว็บไซต์สามารถทำงานได้หรือทำงานได้อย่างมีประสิทธิภาพ
              รวมถึงการให้ข้อมูลแก่เจ้าของเว็บไซต์
            </li>
            <li>
              9.2.
              เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์ของเราผ่านคุกกี้หรือเทคโนโลยีที่คล้ายคลึงกัน
              เพื่อวัตถุประสงค์ดังต่อไปนี้
              <ol>
                <li>
                  9.2.1.
                  เพื่อให้การเข้าบัญชีผู้ใช้งานบนระบบของเราเป็นไปอย่างต่อเนื่องและปลอดภัย
                </li>
                <li>
                  9.2.2.
                  เพื่อบันทึกข้อมูลการใช้งานและการตั้งค่าของท่านบนระบบของเรา
                </li>
                <li>
                  9.2.3. เพื่อวิเคราะห์พฤติกรรมการใช้งานของท่านบนระบบของเรา
                </li>
                <li>
                  9.2.4.
                  เพื่อพัฒนาประสิทธิภาพในการใช้งานและการเข้าถึงบริการของเรา
                </li>
                <li>
                  9.2.5. เพื่อเก็บรวบรวมความสนใจ
                  และเพิ่มความสามารถในการตอบสนองความต้องการในการใช้งานของท่านมากขึ้น
                </li>
              </ol>
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            10. ประเภทของ Cookies
          </li>
          <ol className="mb-[10px]">
            <li>
              10.1. คุกกี้ที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)
              ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน
              หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่ท่านได้
            </li>
            <li>
              10.2. คุกกี้เพื่อการทำงานของเว็บไซต์ (Functional Cookies)
              ช่วยให้เราจดจำสิ่งที่ท่านเลือกเมื่อคุณใช้งานระบบ เช่น
              การจดจำรายละเอียดการเข้าสู่ระบบหรือการตั้งค่าภาษาของท่าน
              คุกกี้เหล่านี้มีเพื่อให้ท่านได้รับประสบการณ์ที่เป็นส่วนตัวมากขึ้นและเพื่อหลีกเลี่ยงไม่ให้ท่านต้องใส่ข้อมูลของท่านใหม่ทุกครั้งเมื่อเข้าใช้งานระบบ
            </li>
            <li>
              10.3. คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ
              (Analytical/Performance Cookies)
              ช่วยให้เราจดจำและนับจำนวนผู้ใช้งานบนไซต์ของเรา
              รวมถึงทำให้เราเข้าใจถึงวิธีการที่ผู้ใช้งานใช้งานบน
              ซึ่งจะช่วยปรับปรุงวิธีการทำงานของเว็บไซต์เราเว็บไซต์ของเรา
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            11. การเปลี่ยนแปลงนโยบายข้อมูลส่วนบุคคล
          </li>
          <p className="mb-[10px]">
            เราอาจแก้ไขเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้เป็นครั้งคราว
            โดยท่านสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์หรือช่องทางอื่นๆ
            ของเรา
          </p>
          <li className="label-b mb-[5px] inline-block">12. ข้อมูลติดต่อเรา</li>
          <p className="mb-[20px]">
            หากท่านมีคำถามเกี่ยวกับแนวทางปฏิบัติและกิจกรรมของเราที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน
            ท่านสามารถสามารถติดต่อสอบถามข้อมูลเพิ่มเติม โทร. Xxxxxxxxx (จันทร์ -
            ศุกร์ เวลา 09.00 – 17.30 น. ยกเว้นวันหยุดนักขัตฤกษ์)
          </p>
        </ol>
      </div>
      <div className="fixed container w-full bottom-0 left-0 py-[35px] bg-white">
        <Link to="/member">
          <button className="w-full h-[45px] body-b bg-light-green text-white rounded-lg">
            กลับสู่หน้าหลัก
          </button>
        </Link>
      </div>
    </>
  );
};

export default Pdpa;
