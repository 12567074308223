import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import BackButton from "components/common/BackButton";
import Box from "@mui/material/Box";
import DatePicker from "react-mobile-datepicker";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Helmet } from "react-helmet";
import Moment from "moment";
import ProfileServices from "services/simulator-bay/Profile";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Swal from "sweetalert2";
import TextField from "components/register/TextField";
import { UserContext } from "contexts/simulator-bay/UserContext";
import withAuth from "hoc/simulator-bay/withAuth";

export interface IInput {
  name: string;
  lastname: string;
  email: string;
  tel: string;
  gender: string;
  birthDate: Date | null;
}

const Edit = () => {
  const navigate = useNavigate();

  const { profile, user, token, onSetUser } = useContext(UserContext);
  const [input, setInput] = useState<IInput>({
    name: user?.first_name || "",
    lastname: user?.last_name || "",
    email: user?.email || "",
    tel: user?.tel || "",
    gender: user?.gender || "",
    birthDate: new Date(user?.birth_date as string) || null,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();

  let isTraining = useMemo(() => {
    let type = searchParams.get("type");
    return type === "training";
  }, [searchParams]);

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">ต้องการยืนยันในการเปลี่ยนแปลงข้อมูลส่วนตัว ใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#EF2F2C",
      cancelButtonText: `<span class="text-red-btn">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        ProfileServices.editProfile(token as string, {
          first_name: input.name,
          last_name: input.lastname,
          gender: input.gender,
          email: input.email,
          birth_date: Moment(input.birthDate).format("YYYY-MM-DD"),
        })
          .then((res) => {
            ProfileServices.getProfile(token as string).then((resProfile) => {
              let userRes = resProfile.data.data;
              onSetUser(userRes);
              Swal.fire("Edit success", "", "success").then(() => {
                navigate(`/simulator-bay/member${isTraining ? "?type=training" : ""}`);
              });
            });
          })
          .catch((err) => {
            window.alert(err.response.data.message);
            console.log(err.response);
          });
      }
    });
  };

  const handleCancelEdit = () => {
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">ข้อมูลที่ทำการกรอกจะไม่ถูกบันทึกในระบบ
      ต้องการออกจากการขั้นตอนการแก้ไขข้อมูล 
      ใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#EF2F2C",
      cancelButtonText: `<span class="text-red-btn">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/simulator-bay/member${isTraining ? "?type=training" : ""}`);
      }
    });
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSelect = (time: Date) => {
    handleInput("birthDate", time);
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Information - CCK GIC</title>
      </Helmet>
      <BackButton
        onClick={() => navigate(`/simulator-bay/member${isTraining ? "?type=training" : ""}`)}
        title="Edit Information"
      />
      <div className="container pt-[80px] pb-[150px]">
        <img
          src={profile?.pictureUrl || "/profile.png"}
          className="flex mx-auto mb-[30px] w-[100px] h-[100px] rounded-full object-cover"
          alt="profile"
        />
        <p className="heading mb-[20px]">Personal Information</p>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="name"
            label="First name"
            type="text"
            name="name"
            onChange={(e) => handleInput("name", e.target.value)}
            value={input.name}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="lastname"
            label="Last name"
            type="text"
            name="lastname"
            onChange={(e) => handleInput("lastname", e.target.value)}
            value={input.lastname}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="email"
            label="Email"
            type="email"
            name="email"
            onChange={(e) => handleInput("email", e.target.value)}
            value={input.email}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            disabled
            required
            id="telno"
            label="Phone number"
            type="tel"
            name="telno"
            value={user?.tel}
          />
          <Box sx={{ height: 20 }} />
          <div className="mb-[25px]">
            <p className="body-b mt-[10px] mb-[15px]">Gender*</p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="gender"
                name="gender"
                value={input.gender}
                onChange={(e) => handleInput("gender", e.target.value)}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Male"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Female"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel value="O" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="mb-[35px]">
            <button
              type="button"
              className={`relative select-btn w-full text-left ${
                input.birthDate ? "text-black" : "text-light-gray"
              } `}
              onClick={handleClick}
            >
              <p
                className={`${
                  input.birthDate
                    ? "absolute top-[-8px] ml-[11px] px-[5px] text-light-gray caption-b bg-white"
                    : "hidden"
                }`}
              >
                วัน/เดือน/ปี (ค.ศ.) เกิด
              </p>
              <div className="p-[15px] border-2 border-light-gray rounded">
                {input.birthDate
                  ? Moment(input.birthDate).format("DD/MM/YYYY")
                  : "วัน/เดือน/ปี (ค.ศ.)"}
              </div>
            </button>
            <DatePicker
              confirmText="ยืนยัน"
              cancelText="ยกเลิก"
              customHeader={<></>}
              dateConfig={{
                date: {
                  format: "DD",
                  caption: "Day",
                  step: 1,
                },
                month: {
                  format: "MM",
                  caption: "Mon",
                  step: 1,
                },
                year: {
                  format: "YYYY",
                  caption: "Year",
                  step: 1,
                },
              }}
              theme="ios"
              value={input.birthDate || new Date()}
              isOpen={isOpen}
              onSelect={handleSelect}
              onCancel={handleCancel}
            />
          </div>
          <div className="fixed bottom-0 left-0 py-[35px] bg-white">
            <div className="container grid grid-cols-2">
              <button
                type="button"
                className="text-red-btn body-b"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white bg-red-btn disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
                disabled={
                  !(
                    input.name &&
                    input.lastname &&
                    input.email &&
                    input.tel &&
                    input.gender &&
                    input.birthDate
                  )
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default withAuth(Edit);
