import { useContext, useEffect, useState } from "react";

import { EditProfileContext } from "pages/EditProfile";
import { IOtpResponse } from "Interfaces/Otp";
import OtpInput from "react-otp-input";
import OtpServices from "services/Otp";
import Swal from "sweetalert2";
import { UserContext } from "contexts/UserContext";

const VerifyCode = () => {
  const { onEditProfile } = useContext(EditProfileContext);
  const { user } = useContext(UserContext);

  const [otp, setOtp] = useState<string>("");
  const [time, setTime] = useState<number>(0);
  const [verify, setVerify] = useState<IOtpResponse | null>(null);

  useEffect(() => {
    onSendOtp();
  }, []);

  useEffect(() => {
    let countTimeout: any;
    if (time && verify) {
      countTimeout = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(countTimeout);
    };
  }, [time, verify]);

  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const onSendOtp = () => {
    // // For test OTP 1111
    // setVerify({
    //   token: "",
    //   refno: "RERER",
    //   status: "success",
    // });
    // setTime(60);

    // For Prod
    OtpServices.send(user?.tel as string)
      .then((res) => {
        setVerify(res.data.data);
        setTime(60);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          html: err.response.data.message,
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#F61E00",
        });
      });
  };

  const handleVerifyCode = () => {
    // // For Test OTP 1111
    // if (otp === "1111") {
    //   onEditProfile()
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     html: "รหัส OTP ไม่ถูกต้อง",
    //     confirmButtonText: "ตกลง",
    //     confirmButtonColor: "#F61E00",
    //   });
    // }

    // For Prod
    OtpServices.verify(verify?.token as string, otp)
      .then(() => {
        onEditProfile()
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          html: err.response.data.message,
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#F61E00",
        });
      });
  };

  if (verify) {
    return (
      <div className="container pt-[80px]">
        <div className="h-[452px]">
          <div className="text-center">
            <p className="title mb-[8px]">รหัสยืนยันตัวตน</p>
            <p className="label-m text-gray mb-[65px]">
              กรุณากรอกรหัสยืนยันตัวตนอีกครั้ง
              <br />
              เนื่องจากมีการเปลี่ยนแปลงบัญชีปลายทาง
            </p>
          </div>
          <div className="mb-[20px]">
            <p className="label-m mb-[5px]">Ref No. {verify?.refno}</p>
            <p className="remark text-red  mb-[10px]">
              *รหัสมีอายุใช้งาน 15 นาที
            </p>
            <OtpInput
              containerStyle={{
                justifyContent: "space-between",
              }}
              inputStyle={{
                width: "55px",
                height: "55px",
                border: "2px solid #D9D9D9",
                borderRadius: "8px",
              }}
              focusStyle={{
                outline: "none",
                border: "2px solid #13BD57",
              }}
              value={otp}
              onChange={handleChange}
              numInputs={4}
              isInputNum
            />
          </div>
          <button
            className="disabled:text-gray text-red flex mx-auto"
            disabled={!(time === 0)}
            onClick={onSendOtp}
          >
            {time === 0
              ? "ส่งรหัสอีกครั้ง"
              : `ไม่ได้รับ OTP? ขออีกครั้งภายใน ${time} วินาที`}
          </button>
        </div>
        <button
          className="body-b bg-light-green text-white disabled:bg-light-gray w-full h-[45px] mb-[15px] rounded-[8px]"
          onClick={handleVerifyCode}
          disabled={!(otp.length === 4)}
        >
          ยืนยันตัวตน
        </button>
      </div>
    );
  } else {
    return <></>;
  }
};

export default VerifyCode;
