import { Link } from "react-router-dom";
import { UserContext } from "contexts/simulator-bay/UserContext";
import { useContext } from "react";

const TrainingBox = () => {
  const { profile, user, token } = useContext(UserContext);

  return (
    <div className="bg-white rounded-[18px] mb-[20px] px-[13px] py-[14px] drop-shadow-[0_0px_40px_rgba(43,43,43,0.1)]">
      <div className="flex items-end justify-between p-5 mb-[10px] bg-white rounded-[18px] drop-shadow-[0_0px_20px_rgba(43,43,43,0.1)]">
        <p className="label-b">Training credits</p>
        <p className="title text-heavy-blue">
          {user?.training_credits.toLocaleString()}{" "}
          <span className="label-b text-black">credits</span>
        </p>
      </div>
      {user?.professional ? (
        <div className="px-[20px] py-[15px] mb-[10px] bg-white rounded-[18px] drop-shadow-[0_0px_40px_rgba(43,43,43,0.1)]">
          <p className="caption-b mb-[10px]">My Professor</p>
          <div className="flex items-center space-x-[30px] mb-2">
            <img
              src={
                user?.professional.profile_url
                  ? process.env.REACT_APP_FILE + user?.professional.profile_url
                  : "/profile.png"
              }
              className="w-[45px] h-[45px] border-[3px] border-heavy-blue rounded-full object-cover"
              alt="profile"
            />
            <div className="w-[calc(100%_-_75px)]">
              <p className="text-heavy-blue label-b">{`${user?.professional.first_name} ${user?.professional.last_name}`}</p>
              <p className="remark text-heavy-blue/50">
                {user?.professional.type}
              </p>
            </div>
          </div>
          <div className="text-center">
            <Link to="/simulator-bay/professional-profiles">
              <span className="underline caption-b">View other professional</span>
            </Link>
          </div>
        </div>
      ) : (
        <Link to="/simulator-bay/professional-profiles">
          <div className="mb-[10px] p-[12px] bg-white rounded-[18px] drop-shadow-[0_15px_40px_rgba(43,43,43,0.1)]">
            <img
              className="h-[38px] mx-auto mb-[15px]"
              src="/images/address-book.png"
              alt="address-book"
            />
            <p className="text-center caption-b text-heavy-blue">
              Professional Profiles
            </p>
          </div>
        </Link>
      )}
      {user?.professional && user.training_credits > 0 ? (
        <Link to={"/simulator-bay/reserve-simulator-training"}>
          <div className="flex flex-col items-center py-[20px] bg-heavy-blue rounded-[18px]">
            <img
              className="h-[38px] mb-[16px]"
              src="/images/golf.png"
              alt="golf"
            />
            <p className="text-white caption-b">Booking for training</p>
          </div>
        </Link>
      ) : (
        <div className="flex flex-col items-center py-[20px] bg-gray rounded-[18px]">
          <img
            className="h-[38px] mb-[16px]"
            src="/images/golf.png"
            alt="golf"
          />
          <p className="text-white caption-b">Booking for training</p>
        </div>
      )}
    </div>
  );
};

export default TrainingBox;
