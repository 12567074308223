import axios from "axios";

const BookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API_CF}/booking`,
});

const BookingServices = {
  booking: (
    data: {
      line_id: string;
      first_name: string;
      last_name: string;
      email?: string;
      tel: string;
      booking_date: string;
      services: number[];
      start_slot_time_id: number;
    }
  ) =>
    BookingService.post(``, data),
};

export default BookingServices;