import { FaSearch } from "react-icons/fa";
import { IProfessional } from "Interfaces/simulator-bay/User";

interface Props {
  professional: IProfessional;
  isMyProfessional: boolean;
}
const ProfessionalCard: React.FC<Props> = ({
  professional,
  isMyProfessional,
}) => {
  return (
    <div className="flex items-center justify-between px-[20px] py-[15px] bg-white rounded-[18px] drop-shadow-[0_0px_20px_rgba(43,43,43,0.1)]">
      <div className="flex items-center space-x-[20px]">
        <img
          src={
            professional.profile_url
              ? process.env.REACT_APP_FILE + professional.profile_url
              : "/profile.png"
          }
          className="object-cover w-[45px] h-[45px] rounded-full"
          alt="profile"
        />
        <div className="w-[calc(100%_-_65px)]">
          <p className="label-b">
            {professional.first_name} {professional.last_name}
          </p>
          <p className="caption-m light-gray">{professional.type}</p>
        </div>
      </div>
      <div className="flex space-x-[5px]">
        {isMyProfessional ? (
          <div className="flex items-center justify-center h-[25px] w-[25px] bg-[#d5e4fc] rounded-md">
            <img className="h-[16px]" src="/images/golfer.png" alt="golfer" />
          </div>
        ) : null}
        <div className="flex items-center justify-center h-[25px] w-[25px] bg-[#f5cfcf] rounded-md">
          <FaSearch className="text-red-btn text-[12px]" />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalCard;
