import axios from "axios";

const profileService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-profile`,
});

const ProfileServices = {
  getProfile: (token: string) =>
    profileService.get("/detail", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  editProfile: (
    token: string,
    data: {
      first_name: string;
      last_name: string;
      gender: string;
      email: string;
      birth_date: string;
    }
  ) =>
    profileService.put(`/edit`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default ProfileServices;
