import { useContext, useEffect, useState } from "react";

import { IReward } from "Interfaces/Reward";
import { Link } from "react-router-dom";
import RewardCard from "./RewardCard";
import RewardServices from "services/Reward";
import { UserContext } from "contexts/UserContext";
import dayjs from "dayjs";

const RewardList = () => {
  const { user } = useContext(UserContext);
  const [reward, setReward] = useState<IReward[] | null>(null);

  useEffect(() => {
    RewardServices.getReward().then((res) => {
      setReward(res.data.data);
    });
  });

  return (
    <>
      <div className="flex flex-col justify-center items-center h-[110px] rounded-[18px] box mb-[42px]">
        <p className="caption-m mb-[5px]">ยอดสั่งซื้อสำหรับแลกรางวัล</p>
        <p className="title">{user?.spend.toLocaleString()} THB</p>
      </div>
      <div className="space-y-[10px] mb-[24px]">
        {reward &&
          user &&
          reward.map((item: IReward) => {
            let disable: boolean = user?.spend < item.spend;
            if (disable) {
              return <RewardCard reward={item} disable={disable} />;
            }
            return (
              <div>
                <Link to={`/reward/${item.id}`}>
                  <RewardCard reward={item} disable={disable} />
                </Link>
              </div>
            );
          })}
      </div>
      <p className="label-b mb-[20px]">เงื่อนไขการสะสมและการแลกรางวัล</p>
      <ul className="mb-[20px]">
        <li className="caption-m list-disc">
          ต้องกดแลกรับคูปองเงินสด เมื่อชำระค่าสินค้าร้าน Only Golf เท่านั้น
        </li>
        <li className="caption-m list-disc">
          สามารถใช้กับการซื้อ online และหน้าร้านได้
        </li>
        <li className="caption-m list-disc">
          คูปองเงินสดสามารถใช้ได้ 1 ครั้ง ต่อ 1 คูปอง ต่อ 1 ใบเสร็จ
        </li>
        <li className="caption-m list-disc">
          คูปองเงินสดไม่สามารถใช้แลก/เปลี่ยนเป็นเงินสดได้
        </li>
        <li className="caption-m list-disc">
          เมื่อใช้คูปองเงินสดจะไม่สามารถทอนได้
        </li>
        <li className="caption-m list-disc">
          เมื่อกดแลกคูปองเงินสดแล้ว จำนวนยอดสะสมจะลดลงตามเงื่อนไขของการแลก
        </li>
        <li className="caption-m list-disc">
          ยอดสั่งซื้อสะสมจะเริ่มเก็บตั้งแต่วันที่ลูกค้าทำการลงทะเบียน
          และยอดจะหมดอายุวันที่ 31 ธันวาคม ของทุกปี
        </li>
      </ul>
      <p className="remark text-right text-red">
        วันครบกำหนด 31/12/{new Date().getFullYear()}
      </p>
    </>
  );
};

export default RewardList;
