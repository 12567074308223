import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthServices from "services/simulator-bay/Auth";
import BackButton from "components/common/BackButton";
import Box from "@mui/material/Box";
import DatePicker from "react-mobile-datepicker";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { SimulatorBayContext } from "pages/simulator-bay/register";
import Swal from "sweetalert2";
import TextField from "./TextField";
import { UserContext } from "contexts/simulator-bay/UserContext";
import { formatPhoneNumber } from "util/common";
import liff from "@line/liff/dist/lib";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const { inputPhone, onChangeStep } = useContext(SimulatorBayContext);

  const [input, setInput] = useState<{
    name: string;
    lastname: string;
    email: string;
    tel: string;
    gender: string;
    birthDate: Date | null;
  }>({
    name: "",
    lastname: "",
    email: "",
    tel: inputPhone,
    gender: "",
    birthDate: null,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { profile } = useContext(UserContext);

  let [searchParams, setSearchParams] = useSearchParams();

  let isTraining = useMemo(() => {
    let type = searchParams.get("type");
    return type === "training";
  }, [searchParams]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">โปรดตรวจสอบข้อมูลก่อนยืนยันการสมัคร ต้องการยืนยันข้อมูลการสมัครสมาชิกใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#EF2F2C",
      cancelButtonText: `<span class="text-red-btn">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        if (profile) {
          AuthServices.register({
            line_id: profile.userLineID,
            first_name: input.name,
            last_name: input.lastname,
            gender: input.gender,
            email: input.email,
            tel: input.tel.replace(/[^\d]/g, ""),
            birth_date: Moment(input.birthDate).format("YYYY-MM-DD"),
          })
            .then((res) => {
              navigate(`/simulator-bay/member${isTraining ? "?type=training" : ""}`);
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                html: err.response.data.message,
                confirmButtonText: "ตกลง",
                confirmButtonColor: "#EF2F2C",
              });
            });
        }
      }
    });
  };

  const handleCancelInfo = () => {
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">ข้อมูลที่ทำการกรอกจะไม่ถูกบันทึกในระบบ
      ต้องการออกจากการขั้นตอนการสมัครสมาชิก ใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#EF2F2C",
      cancelButtonText: `<span class="text-red-btn">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        liff.closeWindow();
      }
    });
  };

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSelect = (time: Date) => {
    setInput({
      ...input,
      birthDate: time,
    });
    setIsOpen(false);
  };

  return (
    <div>
      <BackButton
        onClick={() => onChangeStep(4)}
        title="Personal Information"
      />
      <div className="pt-[80px] pb-[150px]">
        <img
          src={profile?.pictureUrl || "/profile.png"}
          className="flex mx-auto mb-[30px] w-[100px] h-[100px] rounded-full object-cover"
          alt="profile"
        />
        <p className="heading mb-[20px]">Personal Information</p>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="name"
            label="First name"
            type="text"
            name="name"
            onChange={(e) => handleInput("name", e.target.value)}
            value={input.name}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="lastname"
            label="Last name"
            type="text"
            name="lastname"
            onChange={(e) => handleInput("lastname", e.target.value)}
            value={input.lastname}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            required
            id="email"
            label="Email"
            type="email"
            name="email"
            onChange={(e) => handleInput("email", e.target.value)}
            value={input.email}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            disabled
            required
            id="telno"
            label="Phone number"
            type="tel"
            name="telno"
            value={formatPhoneNumber(inputPhone)}
          />
          <Box sx={{ height: 20 }} />
          <div className="mb-[25px]">
            <p className="body-b mt-[10px] mb-[15px]">Gender*</p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="gender"
                name="gender"
                value={input.gender}
                onChange={(e) => handleInput("gender", e.target.value)}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Male"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Female"
                  sx={{
                    marginRight: "40px",
                  }}
                />
                <FormControlLabel value="O" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="mb-[35px]">
            <button
              type="button"
              className={`relative select-btn w-full text-left ${
                input.birthDate ? "text-black" : "text-light-gray"
              } `}
              onClick={handleClick}
            >
              <p
                className={`${
                  input.birthDate
                    ? "absolute top-[-8px] ml-[11px] px-[5px] text-light-gray caption-m bg-white"
                    : "hidden"
                }`}
              >
                DD/MM/YYY(DOB)*
              </p>
              <div className="p-[15px] border-2 border-light-gray rounded">
                {input.birthDate
                  ? Moment(input.birthDate).format("DD/MM/YYYY")
                  : "DD/MM/YYY(DOB)*"}
              </div>
            </button>
            <DatePicker
              confirmText="ยืนยัน"
              cancelText="ยกเลิก"
              customHeader={<></>}
              dateConfig={{
                date: {
                  format: "DD",
                  caption: "Day",
                  step: 1,
                },
                month: {
                  format: "MM",
                  caption: "Mon",
                  step: 1,
                },
                year: {
                  format: "YYYY",
                  caption: "Year",
                  step: 1,
                },
              }}
              theme="ios"
              value={input.birthDate || new Date()}
              isOpen={isOpen}
              onSelect={handleSelect}
              onCancel={handleCancel}
            />
          </div>
          <div className="fixed bottom-0 left-0 py-[35px] bg-white">
            <div className="container grid grid-cols-2">
              <button
                type="button"
                className="text-red-btn body-b"
                onClick={handleCancelInfo}
              >
                ยกเลิก
              </button>
              <button
                type="submit"
                className="text-white bg-red-btn disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
                disabled={
                  !(
                    input.name &&
                    input.lastname &&
                    input.email &&
                    input.tel &&
                    input.gender &&
                    input.birthDate
                  )
                }
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default PersonalInfo;
