import axios from "axios";

const rewardService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/reward`,
});

const RewardServices = {
  redeem: (
    // data: {
    //   bank_id: number;
    //   bank_account_name: string;
    //   bank_account_number: string;
    // },
    reward_id: string,
    token: string
  ) =>
    rewardService.post(`/${reward_id}/redeem`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getReward: () => rewardService.get("/all"),
  getRewardDetail: (reward_id: string) => rewardService.get(`/${reward_id}`),
  getHistory: (token: string) =>
    rewardService.get(`/history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default RewardServices;
