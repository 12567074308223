import axios from "axios";

const TimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-time-slot`,
});

const TimeSlotServices = {
  getAll: (token: string, date: string, bay_number: number) =>
    TimeSlotService.get(`/all/free?date=${date}&bay_number=${bay_number}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAllWithProID: (token: string, date: string, bay_number: number, pro_id: number) =>
    TimeSlotService.get(`/training/all/free?date=${date}&bay_number=${bay_number}&pro_id=${pro_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default TimeSlotServices;
