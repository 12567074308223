import React, { useContext, useEffect, useState } from "react";

import { IReward } from "Interfaces/Reward";
import { UserContext } from "contexts/UserContext";

interface Props {
  reward: IReward[];
}

const ProgressBar: React.FC<Props> = ({ reward }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="relative">
      <div className="px-[15px] py-[30px]">
        <div className="relative">
          <div className="bg-light-gray w-full h-[6px] rounded-[18px]"></div>
          <div
            className="absolute top-0 bg-light-green h-[6px] rounded-[18px]"
            style={{
              width: `${
                ((user?.spend as number) / reward[reward.length - 1].spend) *
                  100 <=
                100
                  ? ((user?.spend as number) /
                      reward[reward.length - 1].spend) *
                    100
                  : 100
              }%`,
            }}
          ></div>
          <img
            className="absolute top-[50%] translate-y-[-50%] translate-x-[-50%] w-[30px] h-[30px] rounded-full"
            src="/images/pro1.png"
            alt="progress1"
          />
          {reward &&
            reward.map((item: IReward, index) => {
              let disable: boolean = (user?.spend as number) < item.spend;
              return (
                <img
                  className={`absolute top-[50%] translate-y-[-50%] translate-x-[-50%] w-[30px] h-[30px] rounded-full`}
                  src={
                    process.env.REACT_APP_FILE +
                    (disable ? `${item.image_disable}` : `${item.image}`)
                  }
                  style={{
                    left: `${(100 / reward.length) * (index + 1)}%`,
                  }}
                  alt="progress"
                />
              );
            })}
        </div>
      </div>
      <p className="remark text-right">
        ยอดแลกรางวัลคงเหลือ
        <br />
        {user?.spend.toLocaleString()} THB
      </p>
    </div>
  );
};

export default ProgressBar;
