import { CircularProgress } from "@mui/material";

const LoadingSection = () => {
  return (
    <div className="flex justify-center py-[150px]">
      <CircularProgress color="success" />
    </div>
  );
};

export default LoadingSection;
