import React, { useContext, useMemo, useState } from "react";

import Edit from "components/editProfile/Edit";
import { Helmet } from "react-helmet";
import Moment from "moment";
import ProfileServices from "services/Profile";
import Success from "components/editProfile/Success";
import { UserContext } from "contexts/UserContext";
import VerifyCode from "components/editProfile/VerifyCode";
import withAuth from "store/withAuth";

export interface IInput {
  name: string;
  lastname: string;
  email: string;
  tel: string;
  gender: string;
  birthDate: Date | null;
  bank: {
    label: string;
    value: string;
  };
  bankAcc: string;
  bankNo: string;
}

export const EditProfileContext = React.createContext<{
  step: number;
  input: IInput;
  onChangeStep: (stepProps: number) => void;
  onEditProfile: () => void;
  handleInput: (name: string, value: any) => void;
}>({
  step: 1,
  input: {
    name: "",
    lastname: "",
    email: "",
    tel: "",
    gender: "",
    birthDate: null,
    bank: {
      label: "",
      value: "",
    },
    bankAcc: "",
    bankNo: "",
  },
  onChangeStep: (stepProps: number) => {},
  onEditProfile: () => {},
  handleInput: (name: string, value: any) => {},
});

const EditProfile = () => {
  const { user, token, onSetUser } = useContext(UserContext);

  const [input, setInput] = useState<IInput>({
    name: user?.first_name || "",
    lastname: user?.last_name || "",
    email: user?.email || "",
    tel: user?.tel || "",
    gender: user?.gender || "",
    birthDate: new Date(user?.birth_date as string) || null,
    bank: {
      label: user?.bank?.bank.name || "",
      value: user?.bank?.bank.id + "" || "",
    },
    bankAcc: user?.bank?.account_name || "",
    bankNo: user?.bank?.account_number || "",
  });
  const [step, setStep] = useState<number>(1);

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <VerifyCode />;
      case 3:
        return <Success />;
      default:
        return <Edit />;
    }
  }, [step]);

  const onEditProfile = () => {
    ProfileServices.editProfile(token as string, {
      first_name: input.name,
      last_name: input.lastname,
      gender: input.gender,
      email: input.email,
      birth_date: Moment(input.birthDate).format("YYYY-MM-DD"),
      bank_id: parseInt(input.bank.value),
      bank_account_name: input.bankAcc,
      bank_account_number: input.bankNo,
    })
      .then((res) => {
        ProfileServices.getProfile(token as string).then((resProfile) => {
          let userRes = resProfile.data.data;
          onSetUser(userRes);
          onChangeStep(3);
        });
      })
      .catch((err) => {
        window.alert(err.response.data.message);
        console.log(err.response);
      });
  };

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Profile - Only Golf</title>
      </Helmet>
      <EditProfileContext.Provider
        value={{
          step,
          input,
          onChangeStep,
          onEditProfile,
          handleInput,
        }}
      >
        {StepComponent}
      </EditProfileContext.Provider>
    </div>
  );
};

export default withAuth(EditProfile);
