import axios from "axios";

const customerService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/customer`,
});

const CustomerServices = {
  get: (tel: string) => customerService.get(`/${tel}`),
};

export default CustomerServices;
