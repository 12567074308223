import { IPromotion } from "Interfaces/Promotion";

interface Props {
  promotion: IPromotion;
}

const PromotionCard: React.FC<Props> = ({ promotion }) => {
  return (
    <div className="flex items-center justify-between w-full px-[12px] py-[15px] space-x-[16px] bg-white box">
      <div>
        <p className="text-red remark-b mb-[5px]">สินค้าโปรโมชัน</p>
        <p className="line-clamp-2 leading-[1rem] h-[2rem] text-red caption-b mb-[5px]">
          {promotion.name}
        </p>
        <p className="line-clamp-3 remark leading-[1rem] h-[3rem]">
          {promotion.description}
        </p>
        <p className="caption-b mt-[10px]">
          {promotion.price.toLocaleString()} THB
        </p>
      </div>
      <img
        className="h-[120px] w-[90px] object-contain"
        src={process.env.REACT_APP_FILE + promotion.image}
        alt="product"
      />
    </div>
  );
};

export default PromotionCard;
