import { FaGift, FaStar, FaTicketAlt } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { IReward } from "Interfaces/Reward";
import { Link } from "react-router-dom";
import Promotions from "components/member/Promotions";
import RewardBox from "components/member/RewardBox";
import RewardServices from "services/Reward";
import Sidebar from "components/Sidebar";
import { UserContext } from "contexts/UserContext";
import withAuth from "store/withAuth";

const Member = () => {
  const { profile, user } = useContext(UserContext);
  const [reward, setReward] = useState<IReward[] | null>(null);

  useEffect(() => {
    RewardServices.getReward().then((res) => {
      setReward(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Member - Only Golf</title>
      </Helmet>
      <div className="bg-green min-h-screen overflow-x-hidden">
        <Sidebar />
        <div className="relative bg-white rounded-t-[35px] min-h-[calc(100vh_-_75px)] pb-[70px]">
          <div className="container">
            <img
              src={profile?.pictureUrl || "/profile.png"}
              className="absolute top-[-37.5px] left-[157.5px] w-[75px] h-[75px] border-4 border-white rounded-full"
              alt="profile"
            />
            <div className="flex pt-[45px] mb-[20px] justify-center items-center">
              <p className="title">
                {user?.first_name} {user?.last_name}
              </p>
            </div>
            <div className="flex items-center justify-between h-[68px] px-[22px] mb-[20px] box">
              <div className="flex items-center">
                <FaStar className="text-light-green text-[30px] mr-[15px]" />
                <p className="body-b">คะแนนสะสม</p>
              </div>
              <p className="title">{user?.point.toLocaleString()}</p>
            </div>
            <div className="grid grid-cols-2 gap-[19px] mb-[20px]">
              <Link to="/coupon">
                <div className="flex flex-col justify-center items-center h-[120px] box">
                  <FaTicketAlt className="text-[57px] text-light-green object-contain" />
                  <p className="label-b">คูปองของฉัน</p>
                </div>
              </Link>
              {reward && reward?.length > 0 ? (
                <Link to="/reward">
                  <div className="flex flex-col justify-center items-center h-[120px] box">
                    <FaGift className="text-[57px] text-light-green object-contain" />
                    <p className="label-b">รางวัลของฉัน</p>
                  </div>
                </Link>
              ) : (
                <div className="flex flex-col justify-center items-center h-[120px] box">
                  <FaGift className="text-[57px] text-light-gray object-contain" />
                  <p className="label-b text-light-gray">รางวัลของฉัน</p>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center items-center h-[135px] mb-[20px] box">
              <p className="caption-m mb-[6px]">ยอดสั่งซื้อสะสม</p>
              <p className="title mb-[10px]">
                {user?.total_spend?.toLocaleString()} THB
              </p>
              <Link to="/ranking">
                <button className="py-[8px] px-[20px] caption-b rounded-lg bg-light-green text-white">
                  อันดับยอดสั่งซื้อสูงสุด
                </button>
              </Link>
            </div>
            {reward && reward.length > 0 && <RewardBox reward={reward} />}
          </div>
          <Promotions />
        </div>
      </div>
    </>
  );
};

export default withAuth(Member);
