import { IReward } from "Interfaces/Reward";
import ProgressBar from "components/member/ProgressBar";
import React from "react";

interface Props {
  reward: IReward[] | null;
}

const RewardBox: React.FC<Props> = ({ reward }) => {
  if (reward) {
    return (
      <div className="box p-[15px]">
        <p className="label-b mb-[10px]">เป้าหมายแลกรางวัล</p>
        <div className="mb-[20px]">
          <ProgressBar reward={reward} />
        </div>
        <div>
          <p className="label-b mb-[8px]">เงื่อนไขในการแลกคูปองเงินสด</p>
          <div className="space-y-[7px]">
            {reward.map((item, index) => {
              return (
                <p className="caption-b text-light-green">
                  LV {index + 1}{" "}
                  <span className="caption-m text-black">
                    สะสมยอดสั่งซื้อครบ {item.spend.toLocaleString()} THB
                    แลกคูปองเงินสด มูลค่า {item.cashback.toLocaleString()} THB
                  </span>
                </p>
              );
            })}
          </div>
          <p className="remark text-red text-right mt-[8px]">
            *สามารถสะสมยอดสั่งซื้อเกินเงื่อนไขในการแลกรางวัลได้
          </p>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default RewardBox;
