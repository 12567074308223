import { useMemo, useState } from "react";

import CouponList from "components/coupon/CouponList";
import { Helmet } from "react-helmet";
import History from "components/coupon/History";
import withAuth from "store/withAuth";

const Coupon = () => {
  const [step, setStep] = useState<number>(1);

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <History />;
      default:
        return <CouponList />;
    }
  }, [step]);

  const onChangeStep = (step: number) => {
    setStep(step);
  };

  return (
    <div className="container pt-[35px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coupon - Only Golf</title>
      </Helmet>
      <div className="grid grid-cols-2 gap-[4px] text-white body-b mb-[12px]">
        <button
          className={`${
            step === 1 ? "bg-light-green" : "bg-light-gray"
          } py-[12px]`}
          onClick={() => onChangeStep(1)}
        >
          คูปองที่ใช้ได้
        </button>
        <button
          className={`${
            step === 2 ? "bg-light-green" : "bg-light-gray"
          } py-[12px]`}
          onClick={() => onChangeStep(2)}
        >
          ประวัติการใช้คูปอง
        </button>
      </div>
      {StepComponent}
    </div>
  );
};

export default withAuth(Coupon);
