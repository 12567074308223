import { useContext, useEffect, useState } from "react";

import FormGroup from "@mui/material/FormGroup";
import { HiChevronDown } from "react-icons/hi";
import { SimulatorBayContext } from "pages/simulator-bay/register";

const Consent = () => {

  const [isTop, setIsTop] = useState<boolean>(true);

  const { onChangeStep } = useContext(SimulatorBayContext);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
    window.onscroll = function (ev) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };
  }, []);

  const handleScroll = () => {
    if (isTop) {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="py-[35px] pb-[115px]">
      <div>
        <p className="title text-center mb-[5px]">
          PDPA
        </p>
        <p className="label-m mb-[10px]">
          บริษัท โอนลี่กอล์ฟ จำกัด
          ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล
          และมีความยินดีเป็นอย่างยิ่งที่ท่านให้ความสนใจในการใช้บริการของเรา
          เราจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้นเพื่อบังคับใช้กับเจ้าของข้อมูลส่วนบุคคลซึ่งใช้บริการต่าง
          ๆ ได้แก่ เว็บไซต์ โซเชียลมีเดีย (Social Media) และช่องทางอื่น ๆ
          ในอนาคต โดยมีหลักเกณฑ์ กลไก มาตรการกำกับดูแล
          และการบริหารจัดการข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
          พ.ศ.2562 ดังรายละเอียดต่อไปนี้
        </p>
        <ol className="list-decimal label-m">
          <li className="label-b mb-[5px] inline-block">1. คำนิยาม</li>
          <ol className="mb-[10px]">
            <li>
              1.1. “บริษัท” หรือ “เรา” หมายความถึง บริษัท โอนลี่กอล์ฟ จำกัด
              และบริษัทในเครือ และให้หมายความรวมถึงบุคคลที่เรามอบหมายด้วย
            </li>
            <li>
              1.2. “ผู้ใช้งาน” หรือ “ท่าน” หมายความถึง ผู้ใช้งานบริการต่างๆ
              ของเราภายใต้ข้อกำหนดเหล่านี้
            </li>
            <li>
              1.3. “ข้อมูลส่วนบุคคล” (Personal Data) หมายความถึง ข้อมูลใด ๆ
              ที่เกี่ยวข้องกับท่านซึ่งสามารถระบุตัวตนของท่านได้โดยตรงหรือโดยอ้อม
              เราอาจ เก็บรวบรวม หรือได้มาซึ่งข้อมูลประเภทต่างๆ
              ที่อาจรวมถึงข้อมูลส่วนบุคคลของท่านโดยตรงจากท่าน
              หรือโดยอ้อมผ่านทางแหล่งที่มาอื่น ๆ
            </li>
            <li>
              1.4. “ข้อมูลที่มีความอ่อนไหว” (Sensitive Personal Data)
              หมายความถึง
              ข้อมูลส่วนบุคคลที่เป็นเรื่องละเอียดอ่อนเป็นการส่วนตัวของบุคคล
              ที่ต้องดำเนินการด้วยความระมัดระวังเป็นพิเศษเพื่อไม่ให้ถูกนำไปใช้ในการเลือกปฏิบัติอย่างไม่เป็นธรรม
            </li>
            <li>
              1.5. “ผู้ควบคุมข้อมูลส่วนบุคคล” (Data Controller) หมายความถึง
              บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจ
              เกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            2. ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม
          </li>
          <p>ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม ประกอบด้วย</p>
          <ol className="mb-[10px]">
            <li>2.1. ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล เพศ วันเกิด</li>
            <li>
              2.2. ข้อมูลการติดต่อ เช่น ที่อยู่บ้าน ที่อยู่ที่ทำงาน
              ที่อยู่เพื่อจัดส่งสินค้า เบอร์โทรศัพท์ อีเมล
            </li>
            <li>
              2.3. ข้อมูลบัญชีผู้ใช้ เช่น ชื่อผู้ใช้ รหัสผ่าน วันที่สร้างบัญชี
              ชื่อของผู้สร้างบัญชีผู้ใช้ ข้อมูลประวัติส่วนตัว
            </li>
            <li>2.4. ข้อมูลทางการเงิน เช่น ข้อมูลบัญชีธนาคาร</li>
            <li>
              2.5. บัญชีผู้ใช้และข้อมูลโซเชียลมีเดีย เช่น Facebook, Instagram,
              YouTube, บัญชีผู้ใช้ LINE, Google, เว็บไซต์ต่าง ๆ
            </li>
            <li>
              2.6. ข้อมูลการจราจรทางคอมพิวเตอร์ ได้แก่ IP address, MAC address,
              Cookie ID เราจะทำการวิเคราะห์ข้อมูลส่วนบุคคลที่ได้เก็บรวบรวม
              เพื่อให้มั่นใจว่าข้อมูลที่เราเก็บรวบรวมเป็นข้อมูลที่จำเป็นจริงๆ
              และการเก็บข้อมูลมีความปลอดภัยเพียงพอ
              เราจะไม่เก็บรวบรวมข้อมูลที่มีความอ่อนไหว สำหรับการดำเนินการตามปกติ
              ยกเว้นมีความจำเป็นต้องเก็บข้อมูลดังกล่าว
              เราจะแจ้งเจ้าของข้อมูลล่วงหน้าเพื่อขอความยินยอมก่อนการเก็บรวบรวม
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            3. แหล่งที่มาของข้อมูลที่เราเก็บรวบรวม
          </li>
          <ol className="mb-[10px]">
            <li>
              3.1. เราได้รับข้อมูลส่วนบุคคลจากท่านโดยตรง ผ่านขั้นตอนดังต่อไปนี้
            </li>
            <ol>
              <li>3.1.1. การสมัคร/ขอใช้บริการ หรือการลงทะเบียน กับเรา</li>
              <li>
                3.1.2. การสั่งซื้อสินค้าและบริการจากเว็บไซต์หรือช่องทางอื่นๆ
                ของเรา
              </li>
              <li>
                3.1.3. การทำแบบสอบถาม โดยความสมัครใจของท่าน
                หรือการตอบกลับอีเมลของท่านกับเรา
                หรือการสื่อสารอื่นใดของท่านกับเรา
              </li>
            </ol>
            <li>
              3.2. เราได้รับข้อมูลส่วนบุคคลของท่าน ผ่านบุคคลที่สาม เช่น
              Facebook, LINE หรือช่องทางอื่นๆ
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            4. วัตถุประสงค์ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่าน
          </li>
          <ol className="mb-[10px]">
            <li>
              4.1. เพื่อให้สามารถติดต่อสื่อสารกับท่าน ที่เกี่ยวข้องกับบริการ
              การดูแลลูกค้า การควบคุมคุณภาพในการให้บริการ
            </li>
            <li>
              4.2. เพื่อสมัครสมาชิก พิสูจน์ตัวตน ระบุตัวตน
              และรับรองตัวตนท่านหรือการยืนยันตัวตนของท่าน
            </li>
            <li>
              4.3. เพื่อให้ท่านสามารถมีส่วนร่วมในการส่งเสริมการขาย ข้อเสนอพิเศษ
              การสะสมคะแนน การแลกของรางวัล การจับรางวัล และข้อเสนอ
              การส่งเสริมการขายอื่นๆ หรือกิจกรรมอื่นใดที่เกี่ยวข้องทั้งหมด
            </li>
            <li>
              4.4. เพื่อให้สามารถส่งอีเมล หรือส่งข้อมูลข่าวสาร โปรโมชั่น
              และอื่นๆ ที่อาจเป็นประโยชน์ต่อท่าน
            </li>
            <li>4.5. เพื่อพัฒนาและปรับปรุงบริการให้ดียิ่งขึ้น</li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            5. การเปิดเผยข้อมูลส่วนบุคคล
          </li>
          <ol className="mb-[10px]">
            <li>5.1. บริษัทในเครือ</li>
            <li>
              5.2.
              บุคคลหรือบริษัทหรือหน่วยงานภายนอกที่เป็นผู้ให้บริการที่เราเป็นผู้ว่าจ้าง
            </li>
            <li>
              5.3. หน่วยงานรัฐ หน่วยงานที่บังคับใช้กฎหมาย ศาล ผู้บังคับใช้กฎหมาย
              หรือบุคคลภายนอกอื่นใดหากเราเชื่อว่ามีความจำเป็นที่จะต้องปฏิบัติหน้าที่ตามกฎหมายหรือระเบียบใดๆ
              หรือต้องคุ้มครองสิทธิของเรา สิทธิของบุคคลที่สาม
              หรือความปลอดภัยส่วนบุคคลของบุคคลใดๆ หรือเพื่อตรวจจับ ป้องกัน
              หรือแก้ไขปัญหาการทุจริต ความมั่นคง หรือความปลอดภัย
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            6. การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
          </li>
          <p className="mb-[10px]">
            เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผลเพื่อให้บรรลุตามวัตถุประสงค์ของการรับข้อมูลส่วนบุคคลนั้นมา
            และเพื่อปฏิบัติหน้าที่ของเรา ตามกฎหมายหรือระเบียบใดๆ
            เราอาจมีความจำเป็นต้องเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาที่นานขึ้นหากมีความจำเป็น
            และ / หรือ อนุญาตให้กระทำได้ตามกฎหมาย
          </p>
          <li className="label-b mb-[5px] inline-block">
            7. การใช้ข้อมูลส่วนบุคคลเพื่อการตลาด
          </li>
          <p className="mb-[10px]">
            ระหว่างที่ท่านใช้บริการของเรา อาจได้รับการส่งข้อมูลผลิตภัณฑ์
            และรายการส่งเสริมการขายใดๆ ที่คาดว่าท่านน่าจะสนใจไปให้
            โดยท่านมีสิทธิที่จะยกเลิกการรับข้อมูลดังกล่าวได้ทุกเวลา
            โดยติดต่อเราผ่านช่องทางที่เรากำหนดไว้ หากท่านยกเลิกการเป็นสมาชิก
            จะทำให้ท่านไม่ได้รับการส่งข้อมูลใดๆ ให้ท่านอีกต่อไป
          </p>
          <li className="label-b mb-[5px] inline-block">
            8. สิทธิของเจ้าของข้อมูล
          </li>
          <p className="mb-[5px]">
            สิทธิตามที่ระบุไว้ในส่วนนี้เป็นสิทธิตามกฎหมายของท่าน
            ท่านสามารถขอใช้สิทธิตามกฎหมายได้ตามเงื่อนไขที่กำหนดไว้ในกฎหมายและกระบวนการจัดการสิทธิของเรา
            สิทธิดังกล่าวมีดังต่อไปนี้
          </p>
          <ol className="mb-[10px]">
            <li>
              8.1. ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผล
              รวมถึงการเพิกถอนการจัดเก็บข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับเราได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับเรา
            </li>
            <li>
              8.2. ท่านมีสิทธิที่จะเข้าถึงข้อมูลส่วนบุคคลของท่าน
              โดยการขอให้เราจัดทำสำเนาข้อมูลส่วนบุคคลของท่านให้ท่าน
              รวมถึงเปิดเผยการได้มาซึ่งข้อมูลของท่าน ซึ่งท่านไม่ได้ยินยอมต่อเรา
            </li>
            <li>
              8.3.
              ท่านมีสิทธิแก้ไขหรือขอให้เราแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์ได้
            </li>
            <li>
              8.4. ท่านมีสิทธิที่จะลบหรือขอให้เราลบข้อมูลส่วนบุคคลของท่านได้
              ผ่านการยกเลิการเป็นสมาชิกกับทางเรา
              ซึ่งทำให้เราไม่สามารถให้บริการท่านได้ต่อไป
            </li>
            <li>
              8.5. ท่านสามารถขอให้เราระงับการใช้ข้อมูลส่วนบุคคลของท่านได้
              ท่านสามารถติดต่อเราผ่านทางที่อยู่ในส่วน "ข้อมูลการติดต่อของเรา"
              ด้านล่างนี้เพื่อขอใช้สิทธิเหล่านี้
              คำขอในการใช้สิทธิเหล่านี้ของท่านอาจถูกจำกัดโดยกฎหมายที่เกี่ยวข้อง
              ในบางกรณีที่เราอาจจำเป็นต้องปฏิเสธคำขอของท่านด้วยเหตุผลและกฎหมายบางประการ
              เช่น เหตุอันเนื่องมาจากหน้าที่ตามกฎหมาย
              หรือคำสั่งศาลทั้งนี้เราจะแจ้งให้ท่านทราบถึงเหตุผลของเรา
              หากเราจำเป็นต้องปฏิเสธคำขอของท่าน
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            9. Cookies และการใช้ Cookies
          </li>
          <ol className="mb-[10px]">
            <li>
              9.1. Cookies
              คือไฟล์ข้อมูลขนาดเล็กที่ฝังอยู่ในคอมพิวเตอร์หรืออุปกรณ์ของคุณเมื่อคุณเยี่ยมชมเว็บไซต์
              หลังจากนั้นคุกกี้จะถูกส่งกลับไปยังเว็บไซต์ต้นทางในแต่ละครั้งของการเข้าเยี่ยมชมในครั้งถัดไปหรือส่งไปยังเว็บไซต์อื่นที่จดจำคุกกี้นั้นได้
              คุกกี้เหล่านี้ถูกใช้เพื่อทำให้เว็บไซต์สามารถทำงานได้หรือทำงานได้อย่างมีประสิทธิภาพ
              รวมถึงการให้ข้อมูลแก่เจ้าของเว็บไซต์
            </li>
            <li>
              9.2.
              เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์ของเราผ่านคุกกี้หรือเทคโนโลยีที่คล้ายคลึงกัน
              เพื่อวัตถุประสงค์ดังต่อไปนี้
              <ol>
                <li>
                  9.2.1.
                  เพื่อให้การเข้าบัญชีผู้ใช้งานบนระบบของเราเป็นไปอย่างต่อเนื่องและปลอดภัย
                </li>
                <li>
                  9.2.2.
                  เพื่อบันทึกข้อมูลการใช้งานและการตั้งค่าของท่านบนระบบของเรา
                </li>
                <li>
                  9.2.3. เพื่อวิเคราะห์พฤติกรรมการใช้งานของท่านบนระบบของเรา
                </li>
                <li>
                  9.2.4.
                  เพื่อพัฒนาประสิทธิภาพในการใช้งานและการเข้าถึงบริการของเรา
                </li>
                <li>
                  9.2.5. เพื่อเก็บรวบรวมความสนใจ
                  และเพิ่มความสามารถในการตอบสนองความต้องการในการใช้งานของท่านมากขึ้น
                </li>
              </ol>
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            10. ประเภทของ Cookies
          </li>
          <ol className="mb-[10px]">
            <li>
              10.1. คุกกี้ที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)
              ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน
              หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่ท่านได้
            </li>
            <li>
              10.2. คุกกี้เพื่อการทำงานของเว็บไซต์ (Functional Cookies)
              ช่วยให้เราจดจำสิ่งที่ท่านเลือกเมื่อคุณใช้งานระบบ เช่น
              การจดจำรายละเอียดการเข้าสู่ระบบหรือการตั้งค่าภาษาของท่าน
              คุกกี้เหล่านี้มีเพื่อให้ท่านได้รับประสบการณ์ที่เป็นส่วนตัวมากขึ้นและเพื่อหลีกเลี่ยงไม่ให้ท่านต้องใส่ข้อมูลของท่านใหม่ทุกครั้งเมื่อเข้าใช้งานระบบ
            </li>
            <li>
              10.3. คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ
              (Analytical/Performance Cookies)
              ช่วยให้เราจดจำและนับจำนวนผู้ใช้งานบนไซต์ของเรา
              รวมถึงทำให้เราเข้าใจถึงวิธีการที่ผู้ใช้งานใช้งานบน
              ซึ่งจะช่วยปรับปรุงวิธีการทำงานของเว็บไซต์เราเว็บไซต์ของเรา
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            11. การเปลี่ยนแปลงนโยบายข้อมูลส่วนบุคคล
          </li>
          <p className="mb-[10px]">
            เราอาจแก้ไขเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้เป็นครั้งคราว
            โดยท่านสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์หรือช่องทางอื่นๆ
            ของเรา
          </p>
          <li className="label-b mb-[5px] inline-block">12. ข้อมูลติดต่อเรา</li>
          <p className="mb-[20px]">
            หากท่านมีคำถามเกี่ยวกับแนวทางปฏิบัติและกิจกรรมของเราที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน
            ท่านสามารถสามารถติดต่อสอบถามข้อมูลเพิ่มเติม โทร. Xxxxxxxxx (จันทร์ -
            ศุกร์ เวลา 09.00 – 17.30 น. ยกเว้นวันหยุดนักขัตฤกษ์)
          </p>
        </ol>
        <p className="title text-center mb-[5px]">
          ข้อกำหนดและเงื่อนไขการใช้งานสำหรับ LINE Official Account
        </p>
        <p className="label-m mb-[10px]">
          <span className="underline label-b">สำคัญ: </span>
          ข้อกำหนดและเงื่อนไขนี้ (“ข้อกำหนด”) ควบคุมการใช้งาน LINE Official
          Account นี้ ซึ่งรวมถึงหน้าทั้งหมดใน LINE Official Account นี้
          (รวมเรียกว่า “LINE OA”)
          โปรดอ่านข้อกำหนดเหล่านี้อย่างระมัดระวังและละเอียดรอบคอบก่อนการใช้งาน
          LINE OA
          เนื่องจากข้อกำหนดเหล่านี้ระบุถึงข้อมูลสำคัญเกี่ยวกับสิทธิและหน้าที่ต่างๆ
          ของท่าน รวมถึงข้อจำกัดและข้อยกเว้นต่างๆ
          ซึ่งมีผลในการทำธุรกรรมและกิจกรรมทั้งหมดบนระบบ LINE OA นี้
        </p>
        <p className="label-m mb-[10px]">
          การที่ท่านสมัครใช้บริการ และ/หรือ ใช้บริการใดๆ ผ่านระบบ LINE OA
          นี้หรือโดยการเลือก “ตกลง” เพื่อยอมรับตามข้อกำหนดเหล่านี้
          ถือว่าท่านได้รับทราบและรับรู้ถึงเนื้อหาของข้อกำหนดเหล่านี้โดยครบถ้วนสมบูรณ์แล้ว
          และตกลงที่จะถูกผูกพันโดยข้อกำหนดเหล่านี้
          เราขอสงวนสิทธิ์ในการเปลี่ยนแปลงหรือแก้ไขข้อกำหนดเหล่านี้ได้ทุกเมื่อตามดุลพินิจของเราแต่เพียงผู้เดียวโดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
          ข้อกำหนดใดที่มีการแก้ไขแล้วจะมีผลบังคับใช้ทันที
          ดังนั้นเป็นความรับผิดชอบของท่านที่ควรตรวจสอบข้อกำหนดเหล่านี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น
          หากท่านยังคงใช้งาน LINE OA นี้ ถือว่าท่านยอมรับการเปลี่ยนแปลงดังกล่าว
        </p>
        <ol className="list-decimal label-m">
          <li className="label-b mb-[5px] inline-block">1. คำนิยาม</li>
          <ol className="mb-[10px]">
            <li>
              1.1. “ข้อกำหนดและเงื่อนไข” หมายความถึง ข้อกำหนดและเงื่อนไขฉบับนี้
            </li>
            <li>
              1.2. “เครื่องมือเพื่อการทำรายการ” หมายความถึง
              โทรศัพท์มือถือและ/หรืออุปกรณ์อิเล็กทรอนิกส์ใดๆ
              ของท่านที่มีการดาวน์โหลดและติดตั้งโปรแกรม LINE Application
              สำหรับใช้บัญชี LINE ที่ท่านขอใช้บริการ LINE OA นี้
            </li>
            <li>
              1.3. “โทรศัพท์สำหรับยืนยันการทำธุรกรรม” หมายความถึง
              หมายเลขโทรศัพท์มือถือที่ท่านแจ้งไว้กับเรา
              ในการใช้บริการที่เกี่ยวกับบัญชีของท่าน ตามข้อกำหนดเหล่านี้
            </li>
            <li>
              1.4. “บริษัท” หรือ “เรา” หมายความถึง บริษัท โอนลี่กอล์ฟ จำกัด
              และบริษัทในเครือ และให้หมายความรวมถึงบุคคลที่เรามอบหมายด้วย
            </li>
            <li>
              1.5. “ผู้ใช้งาน” หรือ “ท่าน” หมายความถึง ผู้ใช้งาน LINE OA
              นี้ของเรา ภายใต้ข้อกำหนดเหล่านี้
            </li>
            <li>
              1.6. “บัญชี LINE” หมายความถึง บัญชีหรือข้อมูลการเป็นผู้ใช้บริการ
              LINE OA นี้ของท่าน
              ซึ่งท่านได้สร้างหรือจัดทำขึ้นโดยการลงทะเบียนสมัคร และ/หรือ
              ขอใช้บริการผ่าน LINE OA นี้ บนเครื่องมือเพื่อทำรายการของท่าน
            </li>
            <li>
              1.7. “ผู้ให้บริการ LINE Application” หมายความถึง บริษัท ไลน์
              คอมพานี (ประเทศไทย) จำกัด และให้หมายความรวมถึงบุคคลที่บริษัท ไลน์
              คอมพานี (ประเทศไทย) จำกัด มอบหมายด้วย
            </li>
            <li>
              1.8. “เนื้อหา” หมายความถึง ข้อมูลเกี่ยวกับสถานที่อยู่ วิดีโอ
              ข้อความเสียง ข้อวิจารณ์ ข้อมูล ข้อความ รูปภาพ โปรแกรมต่างๆ
              ที่ใช้คอมพิวเตอร์ สคริปท์ กราฟฟิก
              และสิ่งที่ใช้ระหว่างกันอื่นใดที่เราเป็นผู้สร้างขึ้น จัดหา
              หรือทำให้เข้าถึงได้ผ่านทางบริการ
            </li>
            <li>
              1.9. “แคมเปญ” หมายความถึง
              กิจกรรมส่งเสริมการตลาดที่เราประกาศลงบนเว็บไซต์หรือ LINE OA นี้
              สำหรับให้ท่านร่วมกิจกรรม
              หรือแลกรับของรางวัลได้ภายในระยะเวลาที่เรากำหนดภายใต้ข้อกำหนดและเงื่อนไขของแต่ละแคมเปญ
            </li>
            <li>
              1.10. “ธุรกรรม” หมายความถึง การที่ท่านทำรายการแลกรับของรางวัลบน
              LINE OA นี้
            </li>
            <li>
              1.11. “พ้อยท์” หรือ “คะแนนสะสม” หมายความถึง
              คะแนนที่เราเป็นผู้กำหนดในการได้มา เพื่อใช้ร่วมกิจกรรม แลกรับสินค้า
              บริการ บัตรกำนัล คูปอง หรือส่วนลดจากแคมเปญที่ประกาศไว้
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            2. ข้อตกลงของข้อกำหนด
          </li>
          <ol className="mb-[10px]">
            <li>
              2.1. การเข้าถึงหรือการใช้งาน LINE OA นี้
              เป็นการยอมรับการผูกพันตามข้อกำหนดเหล่านี้
              หากท่านไม่ตกลงตามข้อกำหนดเหล่านี้
              ท่านจะไม่ได้รับอนุญาตอย่างชัดเจนในการใช้ LINE OA นี้
              และต้องหยุดการใช้งานโดยทันที
            </li>
            <li>
              2.2. ท่านตกลงว่าเนื้อหาที่ท่านเข้าถึงได้โดยการใช้งานบน LINE OA นี้
              ถือเป็นความเสี่ยงภัยของท่านเอง
              และท่านจะต้องรับผิดชอบในความเสียหายหรือสูญหายใดๆ
              ที่เกิดขึ้นกับผู้ใช้งานหรือบุคคลอื่นอันเนื่องมาจากเนื้อหาดังกล่าว
            </li>
            <li>
              2.3. หากท่านเป็นผู้เยาว์จะสามารถใช้งาน LINE OA
              นี้ได้ก็ต่อเมื่อได้รับความยินยอมล่วงหน้าจากบิดามารดาหรือผู้แทนโดยชอบด้วยกฎหมายเท่านั้น
              นอกจากนี้ หากท่านใช้งาน LINE OA นี้
              ในนามหรือเพื่อวัตถุประสงค์ขององค์กรธุรกิจใด
              ให้ถือว่าองค์กรธุรกิจดังกล่าวได้ตกลงยอมรับข้อกำหนดเหล่านี้แล้วล่วงหน้า
            </li>
            <li>
              2.4. เนื้อหา หรือกิจกรรมใดๆ บน LINE OA นี้ อาจมีการโฆษณา
              หรือจัดให้มีการร่วมกิจกรรมกับผู้ใช้งานผ่านทางโปรแกรมของบุคคลที่สาม
              เช่น เฟซบุ๊ก หรือช่องทางอื่นๆ ได้
              ในกรณีทีเป็นการใช้งานผ่านเว็บไซต์หรือโปรแกรมของบุคคลที่สาม
              ท่านอาจถูกร้องขอให้ตกลงและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้งานของบุคคลที่สามดังกล่าว
            </li>
            <li>
              2.5. หากมีข้อกำหนดและเงื่อนไขเพิ่มเติมใดๆ
              ซึ่งเกี่ยวข้องกับการใช้งาน LINE OA นี้
              ท่านจะต้องปฏิบัติตามข้อกำหนดเพิ่มเติมดังกล่าวเช่นเดียวกับข้อกำหนดในการใช้งานฉบับนี้
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            3. การลงทะเบียนและการยกเลิก
          </li>
          <ol className="mb-[10px]">
            <li>
              3.1.
              ท่านไม่จำเป็นต้องสมัครสมาชิกสำหรับการเข้าชมหรือใช้บริการบางประเภทบน
              LINE OA นี้ แต่อย่างไรก็ตามท่านจะต้องสมัครสมาชิก
              เพื่อที่จะมีสิทธิรับคะแนน
              และมีสถานะในการแลกรับของรางวัลที่ประกาศไว้บน LINE OA นี้
              และเพื่อใช้บริการบางประเภทที่มีไว้สำหรับผู้ใช้งานที่สมัครสมาชิกแล้วเท่านั้น
            </li>
            <li>
              3.2. ในการสมัครสมาชิก ท่านจะต้องให้ข้อมูล ชื่อ-นามสกุล
              เบอร์โทรศัพท์ปัจจุบันที่สามารถใช้งานได้ อีเมล์ เพศ วันเดือนปีเกิด
              หรือข้อมูลส่วนตัวอื่นๆ ที่ทางเรากำหนด
              ซึ่งเป็นข้อมูลจริงของผู้ใช้งานเท่านั้น ทั้งนี้
              โปรดดูรายละเอียดเพิ่มเติมในหัวข้อ นโยบายความเป็นส่วนตัว
              เพื่อให้เราสามารถตรวจสอบยืนยันตัวบุคคลผู้ใช้งานได้
              หากท่านสมัครสมาชิกโดยใช้ข้อมูลของบุคคลอื่น
              ทางเรามีสิทธิ์ขอตรวจสอบหลักฐานประกอบเพิ่มเติม
              เพื่อยืนยันตัวตนว่าเป็นเจ้าของบัญชีที่ใช้สมัครสมาชิกกับทางเราจริง
              หากไม่สามารถตรวจสอบได้ เราขอสงวนสิทธิ์ในการระงับการใช้งาน
              หรือร่วมกิจกรรมต่างๆ บน LINE OA นี้
            </li>
            <li>
              3.3. กรณีที่ท่านลงทะเบียนผ่านระบบสำเร็จ
              ท่านจะได้รับการยืนยันการเป็นสมาชิกในทันที
              และท่านสามารถเริ่มสะสมคะแนนได้ทันที
            </li>
            <li>
              3.4.
              เราขอสงวนสิทธิ์ในการยกเลิกบัญชีสมาชิกของท่านโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              ในกรณีที่ตรวจสอบพบว่าท่านทำการทุจริต ส่อเจตนาทุจริต
              หรือทำผิดกฎที่ระบุไว้ในเงื่อนไขและข้อตกลงของแต่ละแคมเปญ
              โดยจะไม่มีการชดเชยคืนแก่ท่านไม่ว่าในรูปแบบใดก็ตาม
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">4. ระดับสมาชิก</li>
          <ol className="mb-[10px]">
            <li>
              4.1. ระดับสมาชิกของเรามีทั้งหมด x ระดับ เริ่มที่ xx, xxx
              และสูงสุดที่ xx
              โดยท่านสามารถเลื่อนขั้นระดับสมาชิกด้วยการสะสมยอดการสั่งซื้อสินค้าของทางเรา
              โดยมีรายละเอียด ดังนี้
              <ol>
                <li>4.1.1. Xx มียอดสั่งซื้อสินค้าสะสม xx บาท</li>
                <li>4.1.2. Xx มียอดสั่งซื้อสินค้าสะสม xx บาท</li>
                <li>4.1.3. Xx มียอดสั่งซื้อสินค้าสะสม xx บาท</li>
              </ol>
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            5. การได้รับคะแนนสะสมและการแลกรับของรางวัล
          </li>
          <ol className="mb-[10px]">
            <li>
              5.1. การได้รับคะแนนสะสม
              <ol>
                <li>
                  5.1.1.
                  ท่านจะได้รับคะแนนสะสมจากยอดสั่งซื้อสินค้าภายในหน้าร้านสาขา
                  Only Golf และการร่วมกิจกรรมต่างๆ บน LINE OA นี้
                  รวมไปถึงกิจกรรมอื่นๆ ที่อาจเกิดขึ้นบนโปรแกรมของบุคคลที่สาม
                </li>
                <li>
                  5.1.2. คะแนนสะสมที่ท่านได้รับ
                  สามารถนำไปให้แลกของรางวัลและสิทธิประโยชน์ต่างๆ บน LINE OA นี้
                  ภายใต้ข้อกำหนดและเงื่อนไข
                </li>
                <li>
                  5.1.3.
                  ท่านสามารถตรวจสอบยอดคงเหลือของคะแนนสะสมหรือยอดการสั่งซื้อสะสมได้จากหน้าข้อมูลสมาชิก
                  (member)
                </li>
                <li>
                  5.1.4. ท่านไม่สามารถโอนคะแนนสะสมให้แก่ผู้ใช้งานท่านอื่น
                  และไม่สามารถแลกเปลี่ยนคะแนนสะสมเป็นเงินสดได้ไม่ว่ากรณีใดๆ
                </li>
                <li>
                  5.1.5. คะแนนสะสมที่ได้รับจะมีอายุการใช้งาน 1 ปี
                  นับจากวันที่มีการลงทะเบียนเข้าใช้งานระบบ LINE OA นี้
                  หากครบกำหนดอายุการใช้งานและท่านยังมีคะแนนสะสมอยู่ในบัญชีของท่าน
                  ทางเราขอสงวนสิทธิ์ในการลบ
                  หรือยกเลิกคะแนนสะสมออกจากระบบโดยไม่จำเป็นต้องแจ้งให้ทราบ
                </li>
                <li>
                  5.1.6. กรณีที่ระบบเกิดข้อผิดพลาด ล้มเหลว ใช้งานไม่ได้
                  ซึ่งอาจกระทบกับฐานข้อมูลที่เกี่ยวข้องกับคะแนนสะสมของผู้ใช้งาน
                  อันเป็นเหตุให้คะแนนสะสมสูญหาย ทางเราขอสงวนสิทธิ์ตัดสินการใดๆ
                  ที่เกี่ยวกับจำนวนคะแนนสะสม และการให้สิทธิประโยชน์ต่างๆ บน LINE
                  OA นี้ และท่านยอมรับคำตัดสินดังกล่าวโดยไม่มีข้อโต้แย้งใดๆ
                  ทั้งสิ้น
                </li>
                <li>
                  5.1.7.
                  ทางเรามีสิทธิในการปรับเปลี่ยนอัตราหรือจำนวนคะแนนสะสมที่ท่านจะได้รับจากแคมเปญต่างๆ
                  โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                </li>
              </ol>
            </li>
            <li>
              5.2. แคมเปญและการแลกรับของรางวัล
              <ol>
                <li>
                  5.2.1.
                  ท่านมีหน้าทีในการศึกษาและทำความเข้าใจข้อกำหนดและเงื่อนไขในการร่วมกิจกรรมและแลกรับคะแนนสะสมในแต่ละแคมเปญ
                  โดยเราจะไม่รับผิดชอบใดๆ
                  ทั้งสิ้นหากท่านเกิดความเข้าใจผิดเกี่ยวกับเนื้อหาหรือข้อกำหนดและเงื่อนไของแคมเปญ
                </li>
                <li>
                  5.2.2. เมื่อท่านส่งคำขอแลกของรางวัลแล้ว
                  ระบบจะทำการตรวจสอบว่าท่านมีคะแนนสะสมเพียงพอ
                  รวมถึงเงื่อนไขตรงกับระบุไว้เพื่อแลกของรางวัลดังกล่าวหรือไม่
                  หากท่านมีคะแนนสะสมเพียงพอและตรงตามเงื่อนไขที่ระบุ
                  ระบบจะทำการอนุมัติการแลกของรางวัลอัตโนมัติทันที
                </li>
                <li>
                  5.2.3. กรณีที่มีการแลกของรางวัลที่ต้องแลกรับ ณ จุดที่กำหนด
                  หรือสถานที่อื่นๆ ที่เรากำหนด
                  ท่านต้องกดแลกของรางวัลและแสดงรหัสที่ได้รับต่อหน้าพนักงานเท่านั้น
                  หากมีการกดแลกของรางวัลก่อนหน้า โดยที่พนักงานไม่ได้รับทราบ
                  เราขอสงวนสิทธิ์ในการแลกรับของรางวัลดังกล่าว
                </li>
                <li>
                  5.2.4. กรณีที่มีการแลกของรางวัลที่ต้องมีการจัดส่ง
                  ระบบจะขอให้ท่านกรอกข้อมูลสำหรับการจัดส่ง
                  ท่านต้องกรอกข้อมูลและตรวจสอบความถูกต้องทุกครั้ง
                  ท่างเราจะไม่รับผิดชอบหากของรางวัลดังกล่าวจัดส่งไปไม่ถึงท่านในกรณีที่ท่านกรอกข้อมูลสำหรับการจัดส่งผิดพลาด
                </li>
                <li>
                  5.2.5. เมื่อท่านส่งคำขอ
                  และตกลงยอมรับข้อกำหนดและเงื่อนไขการแลกของรางวัลแล้ว
                  จะไม่สามารถยกเลิกหรือเปลี่ยนแปลงได้
                  และจะไม่มีการคืนคะแนนให้ไม่ว่ากรณีใดๆ ก็ตาม
                </li>
                <li>
                  5.2.6. เราขอสงวนสิทธิ์ในการยกเลิกคำขอแลกของรางวัลของท่าน
                  ในกรณีที่ข้อมูลของแคมเปญถูกแสดงผิดไป
                  ซึ่งเราจะคืนคะแนนให้แก่ท่านเต็มจำนวน
                  แต่จะไม่มีการคืนเป็นเงินสดไม่ว่ากรณีใดๆ
                </li>
              </ol>
            </li>
            <li>
              5.3. ภาษีอากร
              <ol>
                <li>
                  5.3.1.
                  ท่านรับทราบและตกลงว่าการรับของรางวัลดังกล่าวอาจอยู่ภายใต้ข้อบังคับกฎหมายภาษีอากรที่เกี่ยวข้อง
                  เช่น การหักภาษี ณ ที่จ่าย อากรแสตมป์ ภาษีมูลค่าเพิ่ม เป็นต้น
                </li>
                <li>
                  5.3.2. ท่านที่ได้รับของรางวัลมูลค่าเกิน 1,000 บาท
                  ต้องชำระภาษีหัก ณ ที่จ่าย 5% (ตามคำสั่งกรมสรรพากร ที่
                  ทป.101/2544)
                </li>
              </ol>
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            6. การระงับหรือยกเลิกการให้บริการ
          </li>
          <ol className="mb-[10px]">
            <li>
              6.1. เรามีสิทธิที่จะไม่ให้บริการ หรือระงับ หรือยกเลิกการให้บริการ
              LINE OA นี้ เมื่อใดก็ได้ โดยไม่ต้องแจ้งให้ท่านรับทราบล่วงหน้า
              เมื่อเราพบเหตุดังต่อไปนี้
              <ol>
                <li>
                  6.1.1. เราสงสัยหรือเห็นว่า
                  ท่านมิใช่เจ้าของหรือผู้มีสิทธิ์ใช้หมายเลขโทรศัพท์สำหรับยืนยันการทำธุรกรรม
                  หรือมีการใช้ LINE OA นี้ หรือบัญชี LINE ที่ขัดต่อกฎหมาย
                  หรือความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน
                  หรือมีลักษณะเป็นธุรกรรมที่อาจผิดกฎหมาย หรือในลักษณะที่ผิดปกติ
                  หรือเป็นการฉ้อฉล หรือทุจริต
                  หรือขัดต่อกฎหมายไม่ว่าด้วยประการใดๆ{" "}
                </li>
                <li>
                  6.1.2. เราต้องปฏิบัติตามกฎหมายระเบียบ ข้อบังคับ
                  หรือคำสั่งศาลหรือผู้มีอำนาจตามกฎหมาย
                </li>
              </ol>
            </li>
            <li>
              6.2. ท่านมีสิทธิยกเลิกการใช้งาน LINE OA นี้ เมื่อใดก็ได้
              โยทำรายการผ่านช่องทางที่เราดำเนินการไว้ให้
              หรือแจ้งผ่านช่องทางอื่นใดตามที่เรากำหนดเพิ่มเติม
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            7. นโยบายความเป็นส่วนตัว
          </li>
          <p className="mb-[10px]">
            เราให้ความสำคัญในเรื่องความเป็นส่วนตัวและความปลอดภัยของข้อมูล
            โปรดอ่านนโยบายความเป็นส่วนตัว การใช้ LINE OA นี้
            ถือว่าท่านยอมรับที่จะผูกพันตามประกาศความเป็นส่วนตัวของเรา
            ซึ่งรวบรวมอยู่และเป็นส่วนหนึ่งของข้อกำหนดเหล่านี้
          </p>
          <li className="label-b mb-[5px] inline-block">
            8. การไม่รับประกัน ความรับผิดและข้อยกเว้นความผิดชอบของบริษัทฯ
          </li>
          <ol className="mb-[10px]">
            <li>
              8.1. การใช้ข้อมูลหรือเนื้อหาใดๆ บนระบบ LINE OA นี้
              ถือเป็นความเสี่ยงและเป็นความรับผิดชอบของท่านทั้งสิ้น
            </li>
            <li>
              8.2.
              เราจะใช้ความพยายามตามสมควรในการที่จะแก้ไขข้อผิดพลาดหรือข้อตกหล่นต่างๆ
              ให้เร็วที่สุดหลังจากได้รับแจ้งหรือพบข้อผิดพลาดนั้นๆ
              อย่างไรก็ตามเราไม่รับประกันว่า LINE OA นี้
              จะปราศจากข้อผิดพลาดและจะไม่รับผิดชอบในข้อผิดพลาด
              รวมถึงการสูญหายของคะแนนสะสมของท่าน
            </li>
            <li>
              8.3. เราขอสงวนสิทธิ์ไม่รับผิดชอบในความผิดพลาด
              ความบกพร่องหรือเหตุขัดข้องในการใช้งาน LINE OA
              นี้อันเนื่องมาจากท่านใช้เครื่องมือทำรายการไม่เป็นไปตามที่เรากำหนด
              หรือเกิดจากเหตุสุดวิสัย
              หรือเหตุอื่นใดที่อยู่นอกเหนือการควบคุมของเรา
            </li>
            <li>
              8.4. เราไม่รับประกันว่าระบบ LINE OA นี้
              จะปราศจากไวรัสคอมพิวเตอร์หรือสิ่งอื่นใดที่อาจส่งผลอันตรายต่ออุปกรณ์หรือเครื่องมือในการใช้งานของท่าน
            </li>
            <li>
              8.5. การเข้าถึงระบบ LINE OA นี้
              อาจถูกจำกัดในบางครั้งด้วยเหตุผลในการซ่อมแซมบำรุง
              หรือเพิ่มอุปกรณ์หรือบริการใหม่ เราจะพยายามทำให้การเข้าใช้งาน LINE
              OA นี้กลับมาใช้งานได้ดังเดิมโดยเร็วที่สุด
              ทั้งนี้เราจะไม่รับผิดชอบในระบบการทำงานที่ขึ้นอยู่กับโปรแกรมหรือระบบของบุคคลภายนอก
            </li>
            <li>
              8.6. กรณีมีความผิดพลาด บกพร้อง ล่าช้า หรือมีความเสียหายใดๆ
              เกิดขึ้นจากเครื่องมือเพื่อทำรายการของท่านผ่าน LINE OA
              หรือระบบเครือข่ายโทรศัพท์มือถือ หรือระบบอินเตอร์เน็ต หรือ LINE
              Application ถือเป็นความรับผิดชอบของท่าน
              และ/หรือผู้ให้บริการเครือข่ายโทรศัพท์มือถือ
              และ/หรือผู้ให้บริการระบบอินเตอร์เน็ต และ/หรือผู้ให้บริการ LINE
              Application (แล้วแต่กรณี)
              เราขอสงวนสิทธิ์ไม่รับผิดชอบในความเสียหายใดๆ ต่อท่านหรือบุคคลใด
              ไม่ว่ากรณีใดๆ ทั้งสิ้น
            </li>
            <li>
              8.7.
              บริการอาจประกอบด้วยการเชื่อมโยงท่านไปยังเว็บไซต์หรือโปรแกรมอื่นใดที่อาจมีข้อมูลที่ท่านอาจรู้สึกไม่พอใจหรือเห็นว่าไม่เหมาะสม
              ดังนั้น เราจึงไม่อาจรับรองถึงรายละเอียดของเนื้อหาใดๆ
              ที่ปรากฏหรือสามารถเข้าถึงได้โดยการใช้บริการ
              และเราจะไม่รับผิดชอบหรือรับผิดต่อความถูกต้อง
              การร้องเรียนเกี่ยวกับลิขสิทธิ์ ความชอบด้วยกฎหมาย
              หรือความเหมาะสมของเนื้อหา
            </li>
          </ol>
          <li className="label-b mb-[5px] inline-block">
            9. การชดเชยและชดใช้ค่าเสียหาย
          </li>
          <p className="label-m mb-[10px]">
            ท่านตกลงที่จะชดเชยและชดใช้ความเสียหายให้แก่เราสำหรับความสูญหาย
            ค่าเสียหาย และ/หรือค่าใช้จ่ายทั้งปวง
            อันเป็นผลมาจากการที่ท่านละเมิดหรือผิดเงื่อนไขข้อใดๆ
            ในข้อกำหนดเหล่านี้
          </p>
          <li className="label-b mb-[5px] inline-block">10. เหตุสุดวิสัย</li>
          <p className="label-m mb-[10px]">
            เราไม่ต้องรับผิดชอบในความล่าช้าหรือการไม่ปฏิบัติตามข้อกำหนดเหล่านี้
            เพราะสุดวิสัย อัคคีภัย อุทกภัย อุบัติเหตุ การจลาจล สงคราม
            การแทรกแซงของรัฐ การนัดหยุดงาน ปัญหาด้านแรงงาน ความบกพร่องของอุปกรณ์
            หรือเหตุอื่นใดที่อยู่นอกเหนือการควบคุมของเรา
          </p>
          <li className="label-b mb-[5px] inline-block">
            11. กฎหมายที่บังคับใช้
          </li>
          <p className="mb-[10px]">
            ข้อกำหนดและเงื่อนไขนี้อยู่ภายใต้บังคับและการตีความของกฎหมายแห่งราชอาณาจักรไทย
            โดยคู่สัญญาทั้งสองฝ่ายตกลงให้ศาลไทยเป็นผู้มีอำนาจในการชี้ขาดประเด็นข้อพิพาททั้งปวง
          </p>
        </ol>
        <FormGroup className="mt-[35px]">
          <button
            className="fixed bottom-[120px] flex justify-center items-center w-[42px] h-[42px] right-[35px] bg-red opacity-80 rounded-full"
            type="button"
            onClick={handleScroll}
          >
            <HiChevronDown
              className={`${
                isTop ? "" : "rotate-180"
              } transition-all text-white text-[40px]`}
            />
          </button>
          <div className="fixed w-full bottom-0 left-0 px-[35px] py-[35px] bg-white">
            <button
              type="submit"
              className="text-white bg-red-btn disabled:bg-light-gray body-b rounded-[8px] w-full h-[45px]"
              onClick={() => onChangeStep(2)}
            >
              Accept
            </button>
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default Consent;
