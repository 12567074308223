import { useContext, useMemo } from "react";

import AuthServices from "services/simulator-bay/Auth";
import BackButton from "components/common/BackButton";
import { Box } from "@mui/material";
import { SimulatorBayContext } from "pages/simulator-bay/register";
import Swal from "sweetalert2";
import TextField from "./TextField";
import { formatPhoneNumber } from "util/common";

const InputPhone = () => {
  const { onChangeStep, onSetPhoneNumber, inputPhone } =
    useContext(SimulatorBayContext);

  const onSubmit = () => {
    AuthServices.check_phone_number(inputPhone)
      .then(() => {
        Swal.fire({
          icon: "error",
          html: "หมายเลขโทรศัพท์นี้มีในระบบแล้ว กรุณาลองอีกครั้ง",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#F61E00",
        });
      })
      .catch(() => {
        onChangeStep(4);
      });
  };

  const handleInput = (e: any) => {
    const phoneNumber: string = e.target.value.replace(/[^\d]/g, "");
    if (phoneNumber.length <= 10) {
      onSetPhoneNumber(phoneNumber);
    }
  };

  const isFormatCorrect = useMemo(() => {
    if (inputPhone.length !== 10) {
      return false;
    } else {
      let startPhone = inputPhone.substring(0, 2);
      switch (startPhone) {
        case "06":
        case "08":
        case "09":
          return true;
        default:
          return false;
      }
    }
  }, [inputPhone]);

  return (
    <div>
      <BackButton onClick={() => onChangeStep(2)} title="OTP Verification" />
      <div className="pt-[80px]">
        <div className="text-left">
          <p className="title mb-[8px]">Enter your phone number</p>
          <p className="label-m text-gray mb-[100px]">
            We’ll send an OTP to your phone number
            <br />
            for verification
          </p>
        </div>
        <Box
          className="mb-[155px]"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
        >
          <TextField
            label="Phone number"
            type="tel"
            onChange={(e) => handleInput(e)}
            value={formatPhoneNumber(inputPhone)}
          />
        </Box>
        <div className="fixed left-0 bottom-[30px] w-full px-[35px]">
          <button
            className="body-b bg-red-btn disabled:bg-light-gray text-white w-full h-[45px] rounded-[8px]"
            onClick={onSubmit}
            disabled={!isFormatCorrect}
          >
            Send OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputPhone;
