import axios from "axios";

const storeService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/store`,
});

const StoreServices = {
  findStore: (search: string, lat: string, lng: string) =>
    storeService.get(`/find?search=${search}&lat=${lat}&lng=${lng}`),
};

export default StoreServices;