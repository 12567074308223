import axios from "axios";

const promotionService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/promotion`,
});

const PromotionServices = {
  getAll: () => promotionService.get("/all"),
};

export default PromotionServices;
