import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { IRedeem } from "Interfaces/Redeem";
import { IRewardRedeem } from "Interfaces/Reward";
import ProfileServices from "services/Profile";
import RewardServices from "services/Reward";
import Success from "components/redemtion/Success";
import Summary from "components/redemtion/Summary";
import Swal from "sweetalert2";
import { UserContext } from "contexts/UserContext";
import VerifyCode from "components/redemtion/VerifyCode";
import dayjs from "dayjs";
import withAuth from "store/withAuth";

export interface IInput {
  bank: {
    label: string;
    value: string;
  };
  bankAcc: string;
  bankNo: string;
}

export const RedemtionContext = React.createContext<{
  step: number;
  rewardRedeem: IRewardRedeem | null;
  // input: IInput;
  onChangeStep: (stepProps: number) => void;
  onRedeemReward: () => void;
  // handleInput: (name: string, value: any) => void;
}>({
  step: 1,
  rewardRedeem: null,
  // input: {
  //   bank: {
  //     label: "",
  //     value: "",
  //   },
  //   bankAcc: "",
  //   bankNo: "",
  // },
  onChangeStep: (stepProps: number) => {},
  onRedeemReward: () => {},
  // handleInput: (name: string, value: any) => {},
});

const Redemtion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, token, onSetUser } = useContext(UserContext);

  // const [input, setInput] = useState<IInput>({
  //   bank: {
  //     label: user?.bank?.bank.name || "",
  //     value: user?.bank?.bank.id + "" || "",
  //   },
  //   bankAcc: user?.bank?.account_name || "",
  //   bankNo: user?.bank?.account_number || "",
  // });
  const [redeemReward, setRedeemReward] = useState<IRewardRedeem | null>(null);
  const [step, setStep] = useState<number>(1);

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <VerifyCode />;
      case 3:
        return <Success />;
      default:
        return <Summary />;
    }
  }, [step]);

  const onRedeemReward = () => {
    RewardServices.redeem(
      // {
      //   bank_id: parseInt(input.bank.value),
      //   bank_account_name: input.bankAcc,
      //   bank_account_number: input.bankNo,
      // },
      id as string,
      token as string
    )
      .then((res) => {
        let redeem: IRewardRedeem = res.data.data;
        setRedeemReward(redeem);
        ProfileServices.getProfile(token as string).then((resProfile) => {
          let userRes = resProfile.data.data;
          onSetUser(userRes);
        });
        // Swal.fire({
        //   title: "ยืนยันการทำรายการ",
        //   html: `<p class="label-m mb-3">รายละเอียดรางวัล</p>
        //         <p class="text-light-green body-b mb-3">${redeem?.code}</p>
        //         <p class="label-m">
        //         รางวัล : ${redeem?.reward.cashback} THB<br> 
        //         ใช้งานเมื่อ : ${dayjs(redeem?.redeemed_at).format(
        //           "DD/MM/YYYY HH:mm"
        //         )}
        //         </p>`,
        //   confirmButtonText: `<span class="text-white">เสร็จสิ้น</span>`,
        //   confirmButtonColor: "#13BD57",
        // }).then(() => {
          onChangeStep(3);
        // });
        // ProfileServices.getProfile(token as string).then((resProfile) => {
        //   let userRes = resProfile.data.data;
        //   onSetUser(userRes);
        // });
      })
      .catch((err) => {
        Swal.fire({
          title: "การทำรายการไม่สำเร็จ",
          confirmButtonText: `<span class="text-white">เสร็จสิ้น</span>`,
          confirmButtonColor: "#13BD57",
        }).then(() => {
          navigate("/reward");
        });
      });
  };

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  // const handleInput = (name: string, value: any) => {
  //   setInput({
  //     ...input,
  //     [name]: value,
  //   });
  // };
  return (
    <div className="container pt-[35px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redemption - Only Golf</title>
      </Helmet>
      <RedemtionContext.Provider
        value={{
          step,
          rewardRedeem: redeemReward,
          // input,
          onChangeStep,
          onRedeemReward,
          // handleInput,
        }}
      >
        {StepComponent}
      </RedemtionContext.Provider>
    </div>
  );
};

export default withAuth(Redemtion);
