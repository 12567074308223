import { FaStar } from "react-icons/fa";
import { ICoupon } from "Interfaces/Coupon";

interface Props {
  coupon: ICoupon;
}

const CouponCard: React.FC<Props> = ({ coupon }) => {
  return (
    <div className="flex items-center space-x-[11px] bg-secodary-white py-[10px] px-[11px] rounded-lg">
      <img
        className="h-[80px] w-[80px] object-cover rounded-lg"
        style={{
          filter: coupon.is_enabled ? "grayscale(0%)" : "grayscale(100%)",
        }}
        src={process.env.REACT_APP_FILE + coupon.image}
        alt="coupon-icon"
      />
      <div>
        <p
          className={`label-b line-clamp-1 leading-[1rem] h-[1rem] mb-[5px] ${
            coupon.is_enabled ? "text-black" : "text-light-gray"
          }`}
        >
          {coupon.name}
        </p>
        <p
          className={`caption-m line-clamp-2 leading-[1rem] h-[2rem] mb-[5px] ${
            coupon.is_enabled ? "text-gray" : "text-light-gray"
          }`}
        >
          {coupon.short_description}
        </p>
        <div
          className={`flex items-center space-x-[5px] ${
            coupon.is_enabled ? "text-light-green" : "text-light-gray"
          }`}
        >
          <FaStar className="text-[15px]" />
          <p
            className={`label-b ${
              coupon.is_enabled ? "text-light-green" : "text-light-gray"
            }`}
          >
            {coupon.coupon_type.name === "SPECIAL" ? (
              <>คะแนนเท่ายอดสั่งซื้อ</>
            ) : (
              <>{coupon.point?.toLocaleString()} คะแนน</>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
