import React, { useEffect, useMemo, useState } from "react";

import Consent from "../components/register/Consent";
import { Helmet } from "react-helmet";
import InputPhone from "components/register/InputPhone";
import PersonalInfo from "components/register/PersonalInfo";
import VerifyCode from "components/register/VerifyCode";
import withoutAuth from "store/withoutAuth";

export const RegisterContext = React.createContext({
  step: 1,
  verify: {
    otp: "",
    ref: "",
  },
  inputPhone: "",
  onChangeStep: (stepProps: number) => {},
  onSetVerify: (otpProps: string, refProps: string) => {},
  random: (characters: string, length: number) => {
    return "" as string;
  },
  onSetPhoneNumber: (inputPhoneProps: string) => {},
});

const Register = () => {
  const [step, setStep] = useState<number>(1);
  const [verify, setVerify] = useState<{ otp: string; ref: string }>({
    otp: "",
    ref: "",
  });
  const [inputPhone, setInputPhone] = useState<string>("");

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  const onSetVerify = (otpProps: string, refProps: string) => {
    setVerify({
      otp: otpProps,
      ref: refProps,
    });
  };

  const onSetPhoneNumber = (inputPhoneProps: string) => {
    setInputPhone(inputPhoneProps);
  };

  const random = (characters: string, length: number) => {
    var result = "";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <InputPhone />;
      case 3:
        return <VerifyCode />;
      case 4:
        return <PersonalInfo />;
      default:
        return <Consent />;
    }
  }, [step]);

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register - Only Golf</title>
      </Helmet>
      <RegisterContext.Provider
        value={{
          step,
          verify,
          inputPhone,
          onChangeStep,
          onSetVerify,
          random,
          onSetPhoneNumber,
        }}
      >
        {StepComponent}
      </RegisterContext.Provider>
    </div>
  );
};

export default withoutAuth(Register);
