import axios from "axios";

const TimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API_CF}/time-slot`,
});

const TimeSlotServices = {
  getAll: (data: { line_id: string; date: string }) =>
    TimeSlotService.post(`/list`, data),
};

export default TimeSlotServices;
