import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Autocomplete from "@mui/material/Autocomplete";
import BankServices from "services/Bank";
import Box from "@mui/material/Box";
import Field from "@mui/material/TextField";
import { IBank } from "Interfaces/User";
import { IReward } from "Interfaces/Reward";
import { RedemtionContext } from "pages/Redemtion";
import RewardCard from "components/reward/RewardCard";
import RewardServices from "services/Reward";
import Swal from "sweetalert2";
import TextField from "components/register/TextField";
import { UserContext } from "contexts/UserContext";

const Summary = () => {
  const {  onChangeStep, onRedeemReward } = useContext(RedemtionContext);
  const { user } = useContext(UserContext);

  const [reward, setReward] = useState<IReward | null>(null);
  // const [banks, setBanks] = useState<
  //   | {
  //       label: string;
  //       value: string;
  //     }[]
  //   | null
  // >(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getDetail = () => {
      RewardServices.getRewardDetail(id as string).then((res) => {
        if ((user?.spend as number) < res.data.data.spend) {
          navigate("/reward");
        } else {
          setReward(res.data.data);
        }
      });
    };
    // const getBank = () => {
    //   BankServices.getAll().then((res) => {
    //     setBanks(
    //       res.data.data.map((bank: IBank) => {
    //         return {
    //           label: bank.name,
    //           value: bank.id,
    //         };
    //       })
    //     );
    //   });
    // };
    getDetail();
    // getBank();
  }, []);

  useEffect(() => {});

  const onSubmit = () => {
    // e.preventDefault();
    Swal.fire({
      icon: "question",
      html: "ต้องการยืนยันการทำรายการหรือไม่ ?",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      cancelButtonText: `<span class="text-light-green">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        onChangeStep(2);
        // onRedeemReward();
      }
    });
  };

  if (user && reward) {
    return (
      <div className="pb-[150px]">
        {reward && <RewardCard reward={reward} disable={false} />}
        <p className="heading my-[20px]">สรุปรายการแลกรับคูปองเงินสด</p>
        <div className="space-y-[20px] mb-[30px]">
          <div className="flex justify-between">
            <p>ยอดสั่งซื้อพร้อมใช้งาน</p>
            <p>{user?.spend.toLocaleString()} THB</p>
          </div>
          <div className="flex justify-between">
            <p>ยอดสั่งซื้อเพื่อแลกรางวัล</p>
            <p>{reward?.spend.toLocaleString()} THB</p>
          </div>
          <div className="flex justify-between">
            <p>ยอดสั่งซื้อคงเหลือ </p>
            <p className="text-red">
              {(
                (user?.spend as number) - (reward?.spend as number)
              ).toLocaleString()}{" "}
              THB
            </p>
          </div>
        </div>
        {/* <p className="heading mb-[30px]">ข้อมูลสำหรับการรับเงินรางวัล</p> */}
        {/* <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "100%" },
          }}
          onSubmit={handleSubmit}
        >
          {banks !== null && (
            <div className="mb-[10px]">
              <Autocomplete
                value={input.bank}
                onChange={(event, newValue) => {
                  handleInput("bank", newValue);
                }}
                disablePortal
                id="combo-box-demo"
                options={banks}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <Field {...params} label="เลือกธนาคาร" />
                )}
              />
            </div>
          )}
          <TextField
            id="standard-basic"
            label="ชื่อบัญชีธนาคาร"
            type="text"
            onChange={(e) => handleInput("bankAcc", e.target.value)}
            value={input.bankAcc}
          />
          <Box sx={{ height: 20 }} />
          <TextField
            id="standard-basic"
            label="เลขบัญชีธนาคาร"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={(e) => handleInput("bankNo", e.target.value)}
            value={input.bankNo}
          />
          <p className="caption-m text-red mt-[25px]">
            * จำนวนเงินที่ทำรายการจะถูกโอนเข้าสู่บัญชีปลายทาง ภายหลัง 7 วันทำการ
          </p>
        </Box> */}
        <div className="fixed w-full bottom-0 left-0 py-[35px] bg-white">
          <div className="container grid grid-cols-2">
            <button
              type="button"
              className="text-light-green body-b"
              onClick={() => navigate("/reward")}
              >
              ยกเลิก
            </button>
            <button
              type="button"
              className="text-white bg-light-green disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
              // disabled={!(input.bank && input.bankAcc && input.bankNo)}
              onClick={onSubmit}
            >
              ตกลง
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Summary;
