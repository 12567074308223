import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

import { IStore } from "Interfaces/Store";

interface Props {
  store: IStore;
}

const LocationCard: React.FC<Props> = ({ store }) => {
  return (
    <div className="flex justify-between p-[10px] bg-secodary-white rounded-lg">
      <div className="pr-[10px]">
        <p className="label-b text-black">{store.name}</p>
        <p className="remark  text-gray-25">{store.address}</p>
      </div>
      <div className="flex items-center justify-end">
        <a href={`tel:${store.tel}`}>
          <div className="flex items-center justify-center w-[33px] h-[33px] mr-[6px] bg-blue rounded-full">
            <FaPhoneAlt className="text-white text-[15px]" />
          </div>
        </a>
        <a href={store.url} target="_blank" rel="noreferrer">
          <div className="flex items-center justify-center w-[33px] h-[33px] bg-green rounded-full">
            <FaMapMarkerAlt className="text-white text-[15px]" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default LocationCard;
