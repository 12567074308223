import axios from "axios";

const rankingService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/rank`,
});

const RankingServices = {
  getRanking: (token: string) =>
    rankingService.get(`/rankings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default RankingServices;
