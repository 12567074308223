import { useEffect, useState } from "react";

import { FaSearch } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { IStore } from "Interfaces/Store";
import LocationCard from "components/findstore/LocationCard";
import StoreServices from "services/Store";

const FindStore = () => {
  const [lat, setLat] = useState<any>(null);
  const [lng, setLng] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStore, setLoadingStore] = useState<boolean>(true);
  const [stores, setStores] = useState<IStore[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    let delaySearch: any;
    if (!loading) {
      delaySearch = setTimeout(() => {
        StoreServices.findStore(searchInput, lat || "", lng || "")
          .then((res) => {
            setLoadingStore(false);
            setStores(res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }, 500);
    }
    return () => {
      clearTimeout(delaySearch);
    };
  }, [loading, searchInput]);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setLoading(false);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLoading(false);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find Store - Only Golf</title>
      </Helmet>
      <div className="flex items-center bg-green h-[75px] mb-[35px]">
        <img className="mx-auto" src="/images/logo.png" alt="logo" />
      </div>
      <div className="container">
        <div className="flex relative mb-[35px]">
          <FaSearch className="absolute top-[10.5px] left-[12px] text-gray text-[14px]" />
          <input
            className="bg-heavy-white w-full h-[35px] pl-[35px] pr-[12px] placeholder-gray text-gray focus:outline-green rounded-[8px]"
            placeholder="ค้นหา"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        {/* <button onClick={getLocation}>Get Location</button> */}
        {!(loading && loadingStore) && (
          <div className="space-y-[15px]">
            {stores.map((store) => {
              return <LocationCard key={store.id} store={store} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FindStore;
