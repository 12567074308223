import { CircularProgress } from "@mui/material";

interface Props {
  color?: "success" | "error" | "warning" | "info" | "inherit" | "primary" | "secondary"
}
const Loading:React.FC<Props> = ({color = "success"}) => {
  return (
    <div className="h-screen flex items-center justify-center">
      <CircularProgress color={color} />
    </div>
  );
};

export default Loading;
