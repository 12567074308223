import { getLineProfile, login } from "util/auth";
import { useContext, useEffect, useState } from "react";

import Loading from "components/Loading";
import Swal from "sweetalert2";
import { UserContext } from "contexts/UserContext";
import liff from "@line/liff/dist/lib";
import { useNavigate } from "react-router-dom";

const withoutAuth = (WrapperComponent: any) => (props: any) => {
  let navigate = useNavigate();

  const { onSetProfile, onSetToken, profile, token } =
    useContext(UserContext);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    let lineProfile = profile;
    let tokenRes = token;

    const getReady = async () => {
      if (!lineProfile) {
        await getLineProfile()
          .then((lineProfileRes) => {
            lineProfile = lineProfileRes;
            onSetProfile(lineProfileRes);
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              html: "ไม่สามารถดึงข้อมูลของผู้ใช้งานจากไลน์ได้ กรุณาลองอีกครั้ง",
              confirmButtonText: "ตกลง",
              confirmButtonColor: "#F61E00",
            }).then(() => {
              liff.closeWindow();
            });
          });
      }
      if (lineProfile) {
        if (!tokenRes) {
          await login(lineProfile.userLineID)
            .then((tokenResponse: string) => {
              tokenRes = tokenResponse;
              onSetToken(tokenResponse);
              navigate("/member");
            })
            .catch((err) => {
              setIsReady(true);
            });
        }
        if (tokenRes) {
          navigate("/member");
        }
      }
    };

    getReady();
  }, []);

  if (!isReady) {
    return <Loading />;
  }
  return <WrapperComponent {...props} />;
};

export default withoutAuth;
