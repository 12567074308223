import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useEffect, useState } from "react";

import { IPromotion } from "Interfaces/Promotion";
import PromotionCard from "./PromotionCard";
import PromotionServices from "services/Promotion";
import Slider from "react-slick";

const Promotions = () => {
  const [promotions, setPromotions] = useState<IPromotion[] | null>(null);

  const settings = {
    slidesToShow: 1,
    infinite: false,
    dots: true,
    appendDots: (dots: any) => (
      <div
        style={{
          transform: "translateY(-30px)",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  useEffect(() => {
    PromotionServices.getAll().then((res) => {
      setPromotions(res.data.data);
    });
  }, []);

  return (
    <div>
      <Slider {...settings}>
        {promotions?.map((promotion) => {
          return (
            <a href={promotion.url} target="_black">
              <div className="pt-[30px] pb-[45px] container">
                <PromotionCard promotion={promotion} />
              </div>
            </a>
          );
        })}
      </Slider>
    </div>
  );
};

export default Promotions;
