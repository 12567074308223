import axios from "axios";

const professionalService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-professional`,
});

const ProfessionalServices = {
  getAllProfessional: (search: string) =>
    professionalService.get(`all?search=${search}`),
  getProfessionalById: (id: number) => professionalService.get(`/${id}`),
};

export default ProfessionalServices;
