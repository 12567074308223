import { FaStar } from "react-icons/fa";
import { IRedeem } from "Interfaces/Redeem";
import dayjs from "dayjs";

interface Props {
  history: IRedeem;
}

const HistoryCard: React.FC<Props> = ({ history }) => {
  return (
    <div className="flex items-center space-x-[11px] bg-secodary-white py-[15px] px-[11px] rounded-lg">
      <img
        className="w-[80px] h-[80px] object-cover rounded-lg"
        src={process.env.REACT_APP_FILE + history.coupon.image}
        alt="coupon-icon"
      />
      <div>
        <p className="body-b text-light-gray">รายการ : {history.code}</p>
        <p className="remark-b text-light-gray">
          คูปอง : {history.coupon.name}
        </p>
        <p className="remark-b text-light-gray mb-[5px]">
          ใช้งานเมื่อ : {dayjs(history.created_at).format("DD/MM/YYYY HH:mm")}{" "}
          น.
        </p>
        {history.coupon.coupon_type.name === "SPECIAL" ? (
          <div className="flex items-center space-x-[5px] text-light-gray">
            <FaStar className="text-[15px]" />
            <p className="caption-b">{history.point_used?.toLocaleString()}</p>
          </div>
        ) : (
          <div className="flex items-center space-x-[5px] text-light-gray">
            <FaStar className="text-[15px]" />
            <p className="caption-b">
              {history.coupon.point?.toLocaleString()} คะแนน
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryCard;
