import { useContext, useState } from "react";

import BackButton from "components/common/BackButton";
import BookingServices from "services/simulator-bay/Booking";
import { ReserveSimulatorTrainingContext } from "pages/simulator-bay/ReserveSimulatorTraining";
import Swal from "sweetalert2";
import { UserContext } from "contexts/simulator-bay/UserContext";
import dayjs from "dayjs";
import { formatPhoneNumber } from "util/common";
import liff from "@line/liff/dist/lib";

const Summary = () => {
  const { user, token } = useContext(UserContext);
  const { onChangeStep, input, handleInput } = useContext(
    ReserveSimulatorTrainingContext
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBooking = () => {
    if (!isLoading) {
      setIsLoading(true);
      Swal.fire({
        icon: "question",
        html: `<p class="label-m">Are you sure you want to confirm this booking?</p>`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#2C7AEF",
        cancelButtonText: `<span class="text-[#2C7AEF]">Cancel</span>`,
        reverseButtons: true,
        cancelButtonColor: "white",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let dataProps = {
            bay_number: input.number_of_bay,
            booking_date: input.booking_date,
            start_time: input.start_time,
            total_hour: input.total_hour,
            professional_id: user?.professional?.id || 0,
          };
          BookingServices.booking(token as string, dataProps)
            .then((res) => {
              Swal.fire(
                "Booking success",
                "Booking Code: " + res.data.data.code,
                "success"
              ).then(() => {
                liff
                  .sendMessages([
                    {
                      type: "text",
                      text: "Booking Simulator Bay Code: " + res.data.data.code,
                    },
                  ])
                  .then(function () {
                    liff.closeWindow();
                  });
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                text: "The selected time slot is not available for booking. please try again",
              }).then(() => {
                liff.closeWindow();
              });
            });
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <>
      <BackButton
        onClick={() => onChangeStep(1)}
        title="Booking for training"
        isTraining
      />
      <div className="container pt-[80px] pb-[150px]">
        <div className="space-y-[15px] pb-[120px]">
          <p>
            <span className="font-bold mr-[10px]">Name : </span>
            <span>{`${input.first_name} ${input.last_name}`}</span>
          </p>
          <p>
            <span className="font-bold mr-[10px]">Phone number : </span>
            <span>{formatPhoneNumber(input.tel)}</span>
          </p>
          <p>
            <span className="font-bold mr-[10px]">Email : </span>
            <span>{input.email || "-"}</span>
          </p>
          <p>
            <span className="font-bold mr-[10px]">Professional name : </span>
            <span>
              {user?.professional?.first_name} {user?.professional?.last_name}
            </span>
          </p>
          <p>
            <span className="font-bold mr-[10px]">Booking date : </span>
            <span>
              {dayjs(new Date(input.booking_date)).format("DD/MM/YYYY")}
            </span>
          </p>
          <p>
            <span className="font-bold mr-[10px]">Booking time : </span>
            <span>{`${input.start_time.substring(0, 5)} - ${dayjs(
              new Date(`${input.booking_date} ${input.start_time}`).setHours(
                new Date(
                  `${input.booking_date} ${input.start_time}`
                ).getHours() + input.total_hour
              )
            ).format("HH:mm")}`}</span>
          </p>
          <div>
            <p className="font-bold mb-[15px]">Credits :</p>
            <div className="grid grid-cols-2 gap-y-2">
              <div className="col-span-1 space-y-[10px] label-m">
                <p>Available Training Credits</p>
              </div>
              <div className="col-span-1 text-right space-y-[10px] label-m">
                <p>{user?.training_credits}</p>
              </div>
              <div className="col-span-1 space-y-[10px] label-m">
                <p>Credits for booking</p>
              </div>
              <div className="col-span-1 text-right space-y-[10px] label-m">
                <p>{input.total_hour * input.number_of_bay}</p>
              </div>
              <div className="col-span-1 space-y-[10px] label-m">
                <p>Balance Credits </p>
              </div>
              <div className="col-span-1 text-right space-y-[10px] label-m text-blue">
                {(user?.training_credits as number) -
                  input.total_hour * input.number_of_bay}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full left-0">
          <div className="py-[35px] px-[35px] bg-white  grid grid-cols-2">
            <button
              type="button"
              className="text-[#2C7AEF] body-b"
              onClick={() => onChangeStep(1)}
            >
              Cancel
            </button>
            <button
              disabled={
                isLoading ||
                !user?.professional ||
                (user?.training_credits as number) -
                  input.total_hour * input.number_of_bay <
                  0
              }
              className="text-white bg-[#2C7AEF] disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
              onClick={onBooking}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
