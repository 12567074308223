import axios from "axios";

const memberPointService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SB}/cck-member-point`,
});

const MemberPointServices = {
  GetAll: (token: string) =>
    memberPointService.get("/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default MemberPointServices;
