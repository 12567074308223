import { IProfessional, IUser } from "Interfaces/simulator-bay/User";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import AuthServices from "services/simulator-bay/Auth";
import BackButton from "components/common/BackButton";
import { FaSearch } from "react-icons/fa";
import Loading from "components/Loading";
import ProfessionalCard from "components/simulatorBay/professional/ProfessionalCard";
import ProfessionalServices from "services/simulator-bay/Professional";
import ProfileServices from "services/simulator-bay/Profile";
import liff from "@line/liff";

const ProfessionalProfiles = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<IUser | null>(null);
  const [professionals, setProfessionals] = useState<IProfessional[] | null>(
    null
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    Auth();
  }, []);

  const Auth = async () => {
    await liff
      .init({ liffId: `${process.env.REACT_APP_LIFF_SB}` })
      .catch((err) => {
        setIsReady(true);
      });
    if (liff.isLoggedIn()) {
      let lineProfile = await liff.getProfile();
      AuthServices.login(lineProfile.userId)
        .then(async (res) => {
          let token: string = res.data.token;
          await ProfileServices.getProfile(token)
            .then((res) => {
              let user: IUser = res.data.data;
              setUser(user);
              setIsReady(true);
            })
            .catch((err) => {
              setIsReady(true);
            });
        })
        .catch((err) => {
          setIsReady(true);
        });
    } else {
      setIsReady(true);
    }
  };

  useEffect(() => {
    if (isReady) {
      ProfessionalServices.getAllProfessional(searchInput).then((res) => {
        if (user && user.professional) {
          let professionalsData: IProfessional[] = res.data.data;
          let myPro = professionalsData.filter(
            (p) => p.id === user.professional?.id
          );
          let otherPros = professionalsData.filter(
            (p) => p.id !== user.professional?.id
          );
          setProfessionals([...myPro, ...otherPros]);
        } else {
          setProfessionals(res.data.data);
        }
      });
    }
  }, [searchInput, isReady]);

  if (isReady) {
    return (
      <div>
        <BackButton
          onClick={() => navigate("/simulator-bay/member?type=training")}
          title="Professional Profiles"
          isTraining
        />
        <div className="container pt-[75px] min-h-screen pb-[50px]">
          <p className="mb-[20px] heading">Professionals</p>
          <div className="flex relative mb-[35px]">
            <FaSearch className="absolute top-[9px] left-[12px] text-gray text-[14px]" />
            <input
              className="bg-heavy-white w-full h-[30px] pl-[35px] pr-[12px] placeholder-gray text-gray focus:outline-red-btn rounded-[8px]"
              placeholder="search"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="space-y-[12px]">
            {professionals?.map((professional: IProfessional) => {
              return (
                <div>
                  <Link
                    to={`/simulator-bay/professional-profiles/${professional.id}`}
                  >
                    <ProfessionalCard
                      professional={professional}
                      isMyProfessional={
                        user?.professional?.id === professional.id
                      }
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
        <Loading color="info" />
    );
  }
};

export default ProfessionalProfiles;
