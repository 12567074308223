import axios from "axios";

const serviceService = axios.create({
  baseURL: `${process.env.REACT_APP_API_CF}/service`,
});

const ServiceServices = {
  getAll: () => serviceService.get(`/list`),
};

export default ServiceServices;
