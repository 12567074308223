import axios from "axios";

const otpService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/otp`,
});

const OtpServices = {
  send: (tel: string) => otpService.post(`/${tel}`),
  verify: (token: string, pin: string) =>
    otpService.post(`/verify?token=${token}&pin=${pin}`),
};

export default OtpServices;
