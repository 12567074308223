import { ThemeProvider, createTheme } from "@mui/material";

import { Outlet } from "react-router-dom";
import liff from "@line/liff/dist/lib";
import { useEffect } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["IBM Plex Sans Thai"].join(","),
  },
  palette: {
    primary: {
      main: "#EF2F2C",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "2px",
          borderColor: "#D9D9D9",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#D9D9D9",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#D9D9D9",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },
  },
});

const ClubFitting = () => {
  useEffect(() => {
    const init = async () => {
      await liff
        .init({ liffId: `${process.env.REACT_APP_LIFF_CF}` })
        .catch((err) => {
          throw err;
        });
    };
    init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
};

export default ClubFitting;
